interface IPaginationMeta {
    current_page: number
    from: number
    last_page: number
    path: string
    per_page: number
    to: number
    total: number
}

class PaginationMeta implements IPaginationMeta {
    current_page = 0
    from = 0
    last_page = 0
    path = ''
    per_page = 0
    to = 0
    total = 0

    constructor (dto?: IPaginationMeta) {
        if (dto) {
            this.current_page = dto.current_page
            this.from = dto.from
            this.last_page = dto.last_page
            this.path = dto.path
            this.per_page = dto.per_page
            this.to = dto.to
            this.total = dto.total
        }
    }

    get nextPage (): number {
        return this.current_page + 1
    }

    get onLastPage (): boolean {
        return this.current_page >= this.last_page
    }
}

export {
    IPaginationMeta,
    PaginationMeta
}
