import Vue from 'vue'
import VueI18n from 'vue-i18n'

import {
    advancedSearch,
    alert,
    bos,
    crud,
    errors,
    login,
    messages,
    navigation,
    numberFormats,
    transkom,
    settings
} from '@/i18n/pl'

Vue.use(VueI18n)

export default new VueI18n({
    locale: 'pl',
    messages: {
        pl: {
            // eslint-disable-next-line
            'advanced-search': advancedSearch,
            'alert': alert,
            'bos': bos,
            'crud': crud,
            'errors': errors,
            'login': login,
            'messages': messages,
            'navigation': navigation,
            'transkom': transkom,
            'settings': settings
        }
    },
    numberFormats: numberFormats
})
