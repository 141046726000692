
    import Vue from 'vue'
    import { Component, Prop } from 'vue-property-decorator'
    import TransferMode from '@/store/TransferMode'
    import { Message } from '@/models/data/message'
    import { Account } from '@/models/data/account'
    import { getModule } from 'vuex-module-decorators'
    import Accounts from '@/store/modules/accounts'
    import Notifications from '@/lib/notifications'
    import { Folder } from '@/models/data/folder'
    import Messages from '@/store/modules/messages'

    @Component
    export default class ComponentTransferMessages extends Vue {
        @Prop({ required: true }) transferMode!: TransferMode
        @Prop({ required: true }) messages!: Array<Message>

        accountsModule = getModule(Accounts, this.$store)
        messagesModule = getModule(Messages, this.$store)

        expandedAccounts: number[] = [0,1]

        loading = false
        transferring = false

        active: Array<Folder> = []

        selectedAccount: Account | null = null
        selectedFolder: Folder | null = null

        get operationDoing () : string {
            return this.transferMode === TransferMode.MOVE ? 'messages.transfer.moving' : 'messages.transfer.copying'
        }

        get operationDo () : string {
            return this.transferMode === TransferMode.MOVE ? 'messages.transfer.move' : 'messages.transfer.copy'
        }

        get messagesCount () : number {
            return this.messages ? this.messages.length : 0
        }

        get accounts (): Array<Account> {
            return this.accountsModule.entries.data
        }

        isAccountSelected (account: Account): boolean {
            return this.selectedAccount === account
        }

        isFolderSelected (folder: Folder): boolean {
            return this.selectedFolder === folder
        }

        setLocation (account: Account, folder: Folder): void {
            this.selectedAccount = account
            this.selectedFolder = folder
        }

        close () : void {
            this.active = []
            this.$emit('close')
        }

        async loadFolders () : Promise<void> {
            this.loading = true

            try {
                await this.accountsModule.loadPage()
                await this.accountsModule.loadFolders()
            } catch (e) {
                Notifications.error('Nie udało się załadować danych. Spróbuj ponownie lub skontaktuj się z administracją.')
            }

            this.loading = false
        }

        async transfer () : Promise<void> {
            this.transferring = true
            if (this.selectedAccount === null || this.selectedFolder === null) {
                Notifications.error('Proszę wybrać destynację.')
                return
            }

            if (this.transferMode === TransferMode.MOVE) {
                try {
                    await this.messagesModule.move({
                        messages: this.messages,
                        folder: this.selectedFolder,
                        account: this.selectedAccount
                    })
                    Notifications.success(this.$t('messages.success.move') as string)
                } catch (e) {
                    Notifications.error(this.$t('messages.error.move') as string)
                }
            }

            if (this.transferMode === TransferMode.COPY) {
                try {
                    await this.messagesModule.copy({
                        messages: this.messages,
                        folder: this.selectedFolder,
                        account: this.selectedAccount
                    })
                    Notifications.success(this.$t('messages.success.copy') as string)
                } catch (e) {
                    Notifications.error(this.$t('messages.error.copy') as string)
                }
            }

            this.transferring = false
        }
    }
