import { IIdentifiable, ITimestamped } from '@/models/models'

interface ITag extends IIdentifiable, ITimestamped {
    name: string,
    color: string
}

class Tag implements ITag {
    id = 0
    name = ''
    color = ''
    created_at = ''
    updated_at = ''

    constructor (data?: ITag) {
        if (data !== undefined) {
            this.id = data.id
            this.name = data.name
            this.color = data.color
            this.created_at = data.created_at
            this.updated_at = data.updated_at
        }
    }

}

export {
    ITag, Tag
}
