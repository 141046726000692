import { Action, Module, Mutation } from 'vuex-module-decorators'
import BaseModule from '@/store/modules/base-module'
import { SortersModuleState } from '@/store/state'
import { PaginatedResponse } from '@/models/data/pagination/paginated-response'
// import { Action as SorterAction, Rule, Sorter } from '@/models/data/sorter'
import { Sorter } from '@/models/data/sorter'
import SorterRepository from '@/repositories/sorter-repository'
import Mode from '@/store/mode'
import { cloneDeep } from 'lodash'

@Module({ name: 'sorters', namespaced: true })
export default class Sorters extends BaseModule implements SortersModuleState {
    private static errorTag = 'SortersStoreModule'

    entries = new PaginatedResponse<Sorter>()

    mode: Mode = Mode.CREATE

    selectedSorter = new Sorter()
    newSorter = new Sorter()

    // newRule = new Rule()
    // selectedRule = new Rule()
    //
    // newAction = new SorterAction()
    // selectedAction = new SorterAction()

    @Mutation
    setEntries (entries: PaginatedResponse<Sorter>): void {
        this.entries = entries
    }

    @Mutation
    setMode (mode: Mode): void {
        this.mode = mode
    }

    @Mutation
    setSelectedSorter (sorter: Sorter): void {
        this.selectedSorter = cloneDeep(sorter)
    }

    // @Mutation
    // setSelectedRule (rule: Rule): void {
    //     this.selectedRule = cloneDeep(rule)
    // }
    //
    // @Mutation
    // setSelectedAction (action: SorterAction): void {
    //     this.selectedAction = cloneDeep(action)
    // }
    //
    // // region Rules
    // @Mutation
    // addRule (rule?: Rule): void {
    //     if (this.mode === Mode.EDIT) {
    //         this.selectedSorter.rules.push(rule ?? new Rule)
    //     } else {
    //         this.newSorter.rules.push(rule ?? new Rule())
    //     }
    // }
    //
    // @Mutation
    // removeRule (rule: Rule): void {
    //     if (this.mode === Mode.EDIT) {
    //         this.selectedSorter.rules.splice(this.selectedSorter.rules.indexOf(rule), 1)
    //     } else {
    //         this.newSorter.rules.splice(this.newSorter.rules.indexOf(rule), 1)
    //     }
    // }
    //
    // @Mutation
    // setActiveRule (rule: Rule): void {
    //     this.activeSorter.rules[this.activeSorter.rules.indexOf(rule)] = cloneDeep(rule)
    // }
    // // endregion Rule
    //
    // @Mutation
    // setActiveAction (rule: Rule): void {
    //     this.activeSorter.rules[this.activeSorter.rules.indexOf(rule)] = rule
    // }

    @Action
    async loadEntries (): Promise<void> {
        const data = await SorterRepository.index()
        this.setEntries(data)
    }

    @Action
    async save (sorter: Sorter): Promise<void> {
        if (this.mode === Mode.CREATE) {
            await SorterRepository.store(sorter)
        }

        if (this.mode === Mode.EDIT) {
            await SorterRepository.update(sorter)
        }

        await this.loadEntries()
    }

    @Action
    async remove (sorter: Sorter): Promise<void> {
        await SorterRepository.remove(sorter)
        await this.loadEntries()
    }

    @Action
    async run (sorter: Sorter): Promise<void> {
        await SorterRepository.run(sorter)
    }

    @Action
    async activate (sorter: Sorter): Promise<void> {
        await SorterRepository.activate(sorter)
    }

    @Action
    async deactivate (sorter: Sorter): Promise<void> {
        await SorterRepository.deactivate(sorter)
    }

    get activeSorter (): Sorter {
        if (this.mode === Mode.EDIT) {
            return this.selectedSorter
        } else {
            return this.newSorter
        }
    }

    // get activeRule (): Rule {
    //     if (this.mode === Mode.EDIT) {
    //         return this.selectedRule
    //     } else {
    //         return this.newRule
    //     }
    // }
    //
    // get activeAction (): SorterAction {
    //     if (this.mode === Mode.EDIT) {
    //         return this.selectedAction
    //     } else {
    //         return this.newAction
    //     }
    // }

    get isCreateMode (): boolean {
        return this.mode === Mode.CREATE
    }

    get isEditMode (): boolean {
        return this.mode === Mode.EDIT
    }
}
