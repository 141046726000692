export default {
    aspectRatio: 'Obraz musi być w proporcjach {width}:{height}.',
    confirmed (field: string): string {
        return field === 'password'
            ? 'Hasła muszą się zgadzać.'
            : 'Pola muszą się zgadzać.'
    },
    dimensions: 'Obraz musi być szeroki na co najmniej {width} pikseli i wysoki na co najmniej {height} pikseli.',
    email: 'Pole musi być poprawnym adresem email.',
    ext: 'Plik musi mieć rozszerzenie {ext}.',
    image (field: string): string {
        return field === 'image'
            ? 'Plik musi być obrazem.'
            : 'Wszystkie pliki muszą być obrazami.'
    },
    max: 'Pole nie może być dłuższe niż {length} znaków.',
    maxLength (field: string, params?: Record<string, unknown>): string {
        if (!params) {
            params = { length: '' }
        }

        switch (field) {
            case 'tags':
                return `Maksymalna ilość tagów, które możesz dodać to ${params.length}.`

            case 'images':
                return `Maksymalna ilość obrazów, które możesz dodać to ${params.length}.`

            default:
                return `Maksymalna ilość elementów, które możesz dodać to ${params.length}.`
        }
    },
    max_value: 'Maksymalna wartość pola to {max}.',
    min: 'Pole musi być długie na co najmniej {length} znaków.',
    minLength (field: string, params?: Record<string, unknown>): string {
        if (!params) {
            params = { length: '' }
        }

        return field === 'tags'
            ? `Musisz dodać przynajmniej ${params.length} tagi.`
            : `Musisz dodać przynajmniej ${params.length} elementy.`
    },
    minValue: 'Minimalna wartość pola to {min}.',
    numeric: 'Pole może zawierać tylko cyfry',
    required (field: string): string {
        if (field === 'file') {
            return 'Plik jest wymagany.'
        } else if (field === 'image') {
            return 'Obraz jest wymagany.'
        }

        return 'Pole jest wymagane.'
    },
    size: 'Plik musi być mniejszy niż {size}KB',
    url: 'Pole musi być poprawnym adresem URL'
}
