import { MutationPayload, Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import HttpClient from '@/lib/http-client'
import { StoreState } from '@/store/state'

import Users from '@/store/modules/auth'

export default function vuexPersistReady<T extends StoreState> (store: Store<T>): void {
    store.subscribe(async (mutation: MutationPayload) => {
        if (mutation.type === 'RESTORE_MUTATION') {
            const usersModule = getModule(Users, store)
            const user = usersModule.authenticatedUser

            // First - if user is logged in we setup http client headers, so we dont' run into situation where we need
            // authorization for request and there is no headers present despite fact user is logged in.
            // Settings http client headers happen in users-repository too, but we need to do that on application boot
            if (user !== null) {
                HttpClient.authorize(user.token_type, user.access_token)
            }

            // Then we need to check if users token has not expired.
            // If we do that after setting restored value in store state we encounter weird problem where
            // UI will not reload correctly
            await usersModule.checkTokenExpiration()
        }
    })
}
