import { IIdentifiable } from '@/models/models'
import { Customer } from '@/models/data/customer'

interface IItem extends IIdentifiable {
    title: string
    item_sku: string
    variation_sku: string
    thumbnail_url: string
}

class Item implements IItem {
    id = 0
    title = ''
    item_sku = ''
    variation_sku = ''
    thumbnail_url = ''

    constructor (data?: IItem) {
        if (data !== undefined) {
            this.id = data.id
            this.title = data.title
            this.item_sku = data.item_sku
            this.variation_sku = data.variation_sku
            this.thumbnail_url = data.thumbnail_url
        }
    }
}

interface IShipment {
    carrier: string | null,
    sent_at_date: string | null,
    sent_at_time: string | null,
    shipment_status: string | null
    tracking_number: string | null
    shipping_tracking_url: string | null
}

class Shipment implements IShipment {
    carrier: string | null = null
    sent_at_date: string | null = null
    sent_at_time: string | null = null
    shipment_status: string | null = null
    tracking_number: string | null = null
    shipping_tracking_url: string | null = null

    constructor (data?: IShipment) {
        if (data !== undefined) {
            this.carrier = data.carrier
            this.sent_at_date = data.sent_at_date
            this.sent_at_time = data.sent_at_time
            this.shipment_status = data.shipment_status
            this.tracking_number = data.tracking_number
            this.shipping_tracking_url = data.shipping_tracking_url
        }
    }
}

interface IOrder {
    order_number: string
    ordered_at_date: string
    ordered_at_time: string,
    paid_at_date: string | null,
    paid_at_time: string | null,
    customer: Customer
    items: Array<IItem>
    shipment?: IShipment
}

class Order implements IOrder {
    order_number = ''
    ordered_at_date = ''
    ordered_at_time = ''
    paid_at_date: string | null = null
    paid_at_time: string | null = null
    items: Array<IItem> = []
    customer = new Customer()
    shipment?: Shipment = new Shipment()

    constructor (data?: IOrder) {
        if (data !== undefined) {
            this.order_number = data.order_number
            this.ordered_at_date = data.ordered_at_date
            this.ordered_at_time = data.ordered_at_time
            this.paid_at_date = data.paid_at_date
            this.paid_at_time = data.paid_at_time
            this.items = data.items.map(item => new Item(item))
            this.customer = new Customer(data.customer)
            this.shipment = data.shipment !== null ? new Shipment(data.shipment) : new Shipment()
        }
    }
}

export { Order, Shipment, Item }
export type { IOrder, IShipment, IItem }
