import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate'
import {
    // eslint-disable-next-line camelcase
    confirmed, email, max, max_value, min, min_value, numeric, required
} from 'vee-validate/dist/rules'
import Lang from '@/i18n/pl/vee-validate'

import aspectRatio from '@/validate/rules/aspect-ratio'
import dimensions from '@/validate/rules/dimensions'
import ext from '@/validate/rules/ext'
import image from '@/validate/rules/image'
import maxLength from '@/validate/rules/max-length'
import minLength from '@/validate/rules/min-length'
import size from '@/validate/rules/size'
import url from '@/validate/rules/url'

// Configuration
configure({
    bails: false,
    useConstraintAttrs: false
})

// Load validation rules
extend('aspect_ratio', {
    ...aspectRatio,
    message: Lang.aspectRatio
})
extend('confirmed', {
    ...confirmed,
    message: Lang.confirmed
})
extend('dimensions', {
    ...dimensions,
    message: Lang.dimensions
})
extend('email', {
    ...email,
    message: Lang.email
})
extend('ext', {
    ...ext,
    message: Lang.ext
})
extend('image', {
    ...image,
    message: Lang.image
})
extend('max', {
    ...max,
    message: Lang.max
})
extend('max_length', {
    ...maxLength,
    message: Lang.maxLength
})
extend('max_value', {
    ...max_value,
    message: Lang.max_value
})
extend('min', {
    ...min,
    message: Lang.min
})
extend('min_length', {
    ...minLength,
    message: Lang.minLength
})
extend('min_value', {
    ...min_value,
    message: Lang.minValue
})
extend('numeric', {
    ...numeric,
    message: Lang.numeric
})
extend('required', {
    ...required,
    message: Lang.required
})
extend('size', {
    ...size,
    message: Lang.size
})
extend('url', {
    ...url,
    message: Lang.url
})

// Register components
Vue.component('validation-observer', ValidationObserver)
Vue.component('validation-provider', ValidationProvider)
