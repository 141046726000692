import { IIdentifiable, ITimestamped } from '@/models/models'

interface ITemplate extends IIdentifiable, ITimestamped {
    name: string
    subject: string
    content: string
}

class Template implements ITemplate {
    id = 0
    name = ''
    subject = ''
    content = ''
    created_at = ''
    updated_at = ''

    constructor (data?: ITemplate) {
        if (data !== undefined) {
            this.id = data.id
            this.name = data.name
            this.subject = data.subject
            this.content = data.content
            this.created_at = data.created_at
            this.updated_at = data.updated_at
        }
    }
}

interface IParam {
    text: string
    value: string
}

class Param implements IParam {
    text = ''
    value = ''

    constructor (data?: IParam) {
        if (data !== undefined) {
            this.text = data.text
            this.value = data.value
        }
    }
}

// region Template params

// endregion

export {
    Template, Param
}

export type {
    ITemplate, IParam
}
