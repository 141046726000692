export default {
    success: {
        move: 'Pomyślnie przeniesiono wiadomości.',
        copy: 'Pomyślnie skopiowano wiadomości.',
        send: 'Wiadomość została wysłana.',
    },
    error: {
        move: 'Nie udało się przenieść wiadomości.',
        copy: 'Nie udało się skopiować wiadomości.',
        send: 'Nie udało się wysłać wiadomości.<br>Spróbuj ponownie później — zespół techniczny został poinformowany.',
        search: 'Nie udało się wyszukać wiadomości. Spróbuj ponownie lub skontaktuj się z administracją.'
    },
    transfer: {
        move: 'Przenieś',
        moving: 'Przenoszenie',
        copy: 'Skopiuj',
        copying: 'Kopiowanie'
    },
    all_accounts_folders: 'Wszystkie konta i foldery',
    all_received: 'Wszystkie odebrane',
    common_received_view: 'Połączony widok wiadomości z folderów "Odebrane" w każdym z folderze',
    rename: 'Zmień nazwę',
    name: 'Nazwa',
    create_folder: 'Stwórz folder',
    sorter: {
        edit: 'Edycja sortera',
        rules: 'Reguły',
        rules_parameter: 'parametr',
        rules_operator: 'operator',
        rules_value: 'wartość',
        actions: 'Akcje',
        action: 'akcja'
    },
    description: 'Opis',
    account: 'Konto',
    no_attachments: 'Brak załączników',
    reply: 'Odpowiedz na wiadomość',
    forward: 'Przekaż wiadomość',
    delete: 'Usuń wiadomość',
    security_sources_not_loaded: {
        title: 'Ze względów bezpieczeństwa zewnętrzne źródła zostały zablokowane.',
        load: 'Rozumiem, załaduj'
    },
    selected: {
        as_seen: 'Oznacz zaznaczone jako przeczytane',
        as_unseen: 'Oznacz zaznaczone jako nieprzeczytane',
        delete: 'Usuń zaznaczone',
        tags: {
            assign: 'Przypisz etykiety',
            unassign: 'Usuń przypisanie do etykiet'
        },
        move: 'Przenieś zaznaczone',
        copy: 'Skopiuj zaznaczone'
    },
    conversation_with: 'Konwersacja z ',
    order_contractor_match: 'Dopasuj',
    updated_contractor_order_matching: 'Aktualizuj przypisanie',
    no_contractor_assigned: 'Nie przypisano żadnego kontrahenta.',
    assign_now: 'Przypisz teraz',
    message_relations_management: 'Zarządzanie powiązaniami wiadomości',
    navigation: {
        search: ''
    }
}
