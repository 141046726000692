import { ITimestamped } from '@/models/models'

interface INote extends ITimestamped {
    note: string
    created_at: string
    updated_at: string
}

class Note implements INote {
    note = ''
    created_at = ''
    updated_at = ''

    constructor (data?: INote) {
        if (data !== undefined && data !== null) {
            this.note = data.note
            this.created_at = data.created_at
            this.updated_at = data.updated_at
        }
    }
}

export {
    INote,
    Note
}
