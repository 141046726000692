import HttpClient from '@/lib/http-client'
import { folders as Api } from '@/api'
import { Folder, IFolder } from '@/models/data/folder'
import { Account } from '@/models/data/account'

class FolderRepository {
    async getWithStatusForAccount (account: Account): Promise<IFolder[]> {
        return await HttpClient.getJson<IFolder[]>(Api.withStatus(account))
    }

    async update (account: Account, folder: Folder): Promise<IFolder> {
        const request = {
            path: folder.path,
            new_name: folder.name
        }

        return await HttpClient.patchJson<IFolder>(Api.rename(account), request)
    }

    async remove (account: Account, folder: Folder): Promise<IFolder> {
        return await HttpClient.json<IFolder>(Api.remove(account), 'DELETE', folder)
    }

    async create (account: Account, folder: Folder): Promise<IFolder> {
        return await HttpClient.postJson<IFolder>(Api.create(account), folder)
    }
}

export default new FolderRepository()
