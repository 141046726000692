import Vue from 'vue'
import Vuex from 'vuex'

import { StoreState } from '@/store/state'

import Accounts from '@/store/modules/accounts'
import Auth from '@/store/modules/auth'
import Interface from '@/store/modules/interface'
import Location from '@/store/modules/location'
import Messages from '@/store/modules/messages'
import Status from '@/store/modules/status'
import Tags from '@/store/modules/tags'

import PersistPlugin from '@/store/store-persist'
import VuexPersistReady from '@/store/plugins/vuex-persist-ready'
import { RESTORE_MUTATION } from '@/store/plugins/vuex-persist'
import Sorters from '@/store/modules/sorters'
import Templates from '@/store/modules/templates'
// import Folders from '@/store/modules/folders'

Vue.use(Vuex)

const store = new Vuex.Store<StoreState>({
    modules: {
        // folders: Folders,
        accounts: Accounts,
        auth: Auth,
        interface: Interface,
        location: Location,
        messages: Messages,
        sorters: Sorters,
        status: Status,
        tags: Tags,
        templates: Templates
    },
    mutations: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        RESTORE_MUTATION: RESTORE_MUTATION
    },
    plugins: [PersistPlugin, VuexPersistReady],
    strict: process.env.NODE_ENV === 'development'
})

export default store
