import { RuleParamSchema } from 'vee-validate/dist/types/types'

/* eslint-disable @typescript-eslint/no-explicit-any */
const validate = (files: File | Array<File> | undefined | null, params: Record<string, any>): boolean => {
    if (!Array.isArray(params.ext)) {
        params.ext = [params.ext]
    }
    const regex = new RegExp(`.(${params.ext.join('|')})$`, 'i')

    if (Array.isArray(files)) {
        return files.every(file => regex.test(file.name))
    }

    if (files !== undefined && files !== null) {
        return regex.test(files.name)
    }

    return false
}

const params: RuleParamSchema[] = [{ name: 'ext' }]

export default {
    validate,
    params
}
