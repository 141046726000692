import { RuleParamSchema } from 'vee-validate/dist/types/types'

/* eslint-disable @typescript-eslint/no-explicit-any */
const validate = (value: Array<unknown> | string | number | undefined | null, params: Record<string, any>): boolean => {
    if (value === undefined || value === null) {
        return false
    }

    if (typeof value === 'number') {
        value = String(value)
    }

    if (!value.length) {
        value = [value]
    }

    return value.length >= params.length
}

const params: RuleParamSchema[] = [
    {
        name: 'length',
        cast (value: string): number {
            return Number(value)
        }
    }
]

export default {
    validate,
    params
}
