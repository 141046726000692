import { IIdentifiable } from '@/models/models'
import { Account, IAccount } from '@/models/data/account'
import { ITag } from '@/models/data/tag'
import { INote, Note } from '@/models/data/note'
import { MessageFlagName, MessageWriteMode } from '@/api/enums'

// region Message Data
interface IMessageFlag {
    name: MessageFlagName
    value: boolean
}

interface ICorrespondent extends IIdentifiable {
    mail: string | null
    name: string | null
    mail_name: string | null
}

class Correspondent implements ICorrespondent {
    id = 0
    mail: string | null = null
    name: string | null = null
    mail_name: string | null = null

    constructor (data?: ICorrespondent) {
        if (data !== undefined) {
            this.id = data.id
            this.mail = data.mail
            this.name = data.name
            this.mail_name = data.mail_name
        }
    }
}

interface IMessageData {
    from?: ICorrespondent | null
    to: Array<ICorrespondent> | null
    subject: string
    text_body: string | null
    images: Array<string> | null
    date: string
    has_attachments: boolean
    flags: {
        answered: boolean,
        deleted: boolean,
        draft: boolean,
        flagged: boolean,
        recent: boolean,
        seen: boolean
    }
}

class MessageData implements IMessageData {
    from?: Correspondent | null = null
    to: Array<ICorrespondent> = []
    subject = ''
    text_body: string | null = ''
    images: Array<string> | null = null
    date = ''
    has_attachments = false
    flags = {
        answered: false,
        deleted: false,
        draft: false,
        flagged: false,
        recent: false,
        seen: false
    }

    constructor (data?: IMessageData) {
        if (data !== undefined) {
            this.from = data.from ? new Correspondent(data.from) : null
            this.to = data.to ? data.to.map((correspondent: Correspondent): Correspondent => new Correspondent(correspondent)) : []
            this.subject = data.subject
            this.text_body = data.text_body
            this.images = data.images
            this.subject = data.subject
            this.date = data.date
            this.has_attachments = data.has_attachments
            if (data.flags) {
                this.flags = {
                    answered: data.flags.answered,
                    deleted: data.flags.deleted,
                    draft: data.flags.draft,
                    flagged: data.flags.flagged,
                    recent: data.flags.recent,
                    seen: data.flags.seen
                }
            }
        }
    }
}
// endregion

// region Message Details
interface IMessageContractor {
    nickname: string | null
    messages_count?: number | null
}

interface IMessageOrder {
    order_id: string
    messages_count?: number | null
    is_confirmed: boolean
}

interface IMessageDetails {
    account: IAccount
    contractor: IMessageContractor | null
    labels: Array<ITag>
    note?: Note
    order: IMessageOrder | null
}

class MessageDetails implements IMessageDetails {
    account: IAccount = new Account()
    contractor: IMessageContractor = { nickname: null }
    labels: Array<ITag> = []
    note: INote = new Note()
    order: IMessageOrder | null = null

    constructor (data?: IMessageDetails) {
        if (data !== undefined) {
            this.account = data.account
            this.contractor = data.contractor ?? { nickname: null }
            this.labels = data.labels ?? []
            this.note = data.note ? new Note(data.note) : new Note()
            this.order = data.order ?? null
        }
    }
}

// endregion

interface IAttachment {
    content_type: string
    name: string
    size: string
    part_number: number
    src: string
    content: string
}

class Attachment implements IAttachment {
    content = ''
    content_type = ''
    name = ''
    part_number = 0
    size = ''
    src = ''

    constructor (data?: IAttachment) {
        if (data !== undefined) {
            this.content = data.content
            this.content_type = data.content_type
            this.name = data.name
            this.part_number = data.part_number
            this.size = data.size
            this.src = data.src
        }
    }
}

// region Message
interface IMessage extends IIdentifiable {
    identifiers: {
        message_id: string
        path: string
        uid: number
        account_id: number
    }
    loading: boolean
    data: IMessageData
    details: IMessageDetails
    attachments: Array<Attachment>
}

class Message implements IMessage {
    id = 0
    identifiers = {
        message_id: '',
        path: '',
        uid: 0,
        account_id: 0
    }
    loading = false

    data: MessageData = new MessageData()
    details: MessageDetails = new MessageDetails()
    attachments: Array<Attachment> = []

    constructor (data?: IMessage) {
        if (data !== undefined) {
            this.id = data.id
            this.identifiers = data.identifiers
            this.data = new MessageData(data.data)
            this.details = new MessageDetails(data.details)
        }
    }
}

// endregion

interface IDraft {
    subject: string,
    to: string,
    from: Account,
    body: string,
    previousMessage: Message | null,
    attachments: Array<File>,
    mode: MessageWriteMode
}

class Draft implements IDraft {
    subject = ''
    to = ''
    from: Account = new Account()
    body = ''
    previousMessage: Message | null = null
    attachments: Array<File> = []
    mode: MessageWriteMode = MessageWriteMode.NEW

    constructor (data?: IDraft) {
        if (data !== undefined) {
            this.subject = data.subject
            this.to = data.to
            this.from = data.from
            this.body = data.body
            this.previousMessage = data.previousMessage
            this.attachments = data.attachments
            this.mode = data.mode
        }
    }
}

export {
    IMessage,
    Message,
    IMessageFlag,
    IAttachment,
    Attachment,
    IDraft,
    Draft
}
