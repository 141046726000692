import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { TagsModuleState } from '@/store/state'
import { Tag } from '@/models/data/tag'
import TagRepository from '@/repositories/tag-repository'
import Mode from '@/store/mode'
import { clone } from 'lodash'

@Module({ name: 'tags', namespaced: true })
export default class Tags extends VuexModule implements TagsModuleState {
    entries: Array<Tag> = []
    selectedEntry: Tag = new Tag()
    newEntry: Tag = new Tag()
    mode: Mode = Mode.CREATE

    @Mutation
    setEntries (tags: Array<Tag>): void {
        this.entries = tags
    }

    @Mutation
    setNewEntry (): void {
        this.newEntry = new Tag()
    }

    @Mutation
    setSelectedEntry (tag: Tag): void {
        this.selectedEntry = clone(tag)
    }

    @Mutation
    setMode (mode: Mode): void {
        this.mode = mode
    }

    @Mutation
    setProperty<V extends keyof Tag> (payload: { key: V, value: Tag[V] }): void {
        if (this.mode === Mode.CREATE) {
            this.newEntry[payload.key] = payload.value
        } else {
            this.selectedEntry[payload.key] = payload.value
        }
    }

    @Mutation
    setName (value: string): void {
        switch (this.mode) {
            case Mode.CREATE: this.newEntry.name = value; break
            case Mode.EDIT:   this.selectedEntry.name = value; break
        }
    }

    @Mutation
    removeEntry (tag: Tag): void {
        this.entries.splice(this.entries.indexOf(tag), 1)
    }

    @Action
    async loadEntries (): Promise<void> {
        const data = await TagRepository.index()
        this.setEntries(data)
    }

    @Action
    async update (): Promise<void> {
        await TagRepository.update(this.selectedEntry)
        await this.loadEntries()
    }

    @Action
    async create (): Promise<void> {
        await TagRepository.create(this.newEntry)
        await this.loadEntries()
        this.setNewEntry()
    }

    @Action
    async remove (tag: Tag): Promise<void> {
        await TagRepository.remove(tag)
        this.removeEntry(tag)
    }
}
