import { merge } from 'lodash'
import { MutationPayload, Plugin, Store } from 'vuex'

import { VuexPersistDelegate } from '@/store/plugins/vuex-persist/delegates/delegate'

import { Options } from '@/store/plugins/vuex-persist/options'

class StorageDelegate<S extends Record<string, unknown>, T = Partial<S>> implements VuexPersistDelegate<S, T> {
    private options: Options<S, T>

    public constructor (options: Options<S, T>) {
        this.options = options
    }

    public restoreState (key: string): S {
        const value = (this.options.storage as Storage).getItem(key)

        if (value === null) {
            return {} as S
        }

        return JSON.parse(value)
    }

    public saveState (key: string, state: S | T): void {
        return (this.options.storage as Storage).setItem(key, JSON.stringify(state))
    }

    get plugin (): Plugin<S> {
        return (store: Store<S>) => {
            const restoredState = this.restoreState(this.options.key)

            if (this.options.strictMode) {
                store.commit('RESTORE_MUTATION', restoredState)
            } else {
                store.replaceState(merge({}, store.state, restoredState))
            }

            store.subscribe((mutation: MutationPayload, state: S) => {
                if (this.options.filter(mutation)) {
                    this.saveState(this.options.key, this.options.reducer(state))
                }
            })
        }
    }
}

export {
    StorageDelegate
}
