import Vue from 'vue'
import Notifications from 'vue-notification'
import lang from '@/lang'

Vue.use(Notifications, {
    type: 'warn'
})

class Notification {
    private static fire (type: string, title: string, message: string): void {
        Vue.notify({
            type: type,
            title: title,
            text: message
        })
    }

    success (message: string): void {
        Notification.fire('success', lang.tc('alert.success'), message)
    }

    warning (message: string): void {
        Notification.fire('warning', lang.tc('alert.warning'), message)
    }

    error (message: string): void {
        Notification.fire('error', lang.tc('alert.error'), message)
    }

    info (message: string): void {
        Notification.fire('info', lang.tc('alert.info'), message)
    }
}

export default new Notification()
