import HttpClient from '@/lib/http-client'
import { accounts as Api } from '@/api'
import { Account, IAccount } from '@/models/data/account'
import { IPaginatedResponse, PaginatedResponse } from '@/models/data/pagination/paginated-response'
import { BaseResponse } from '@/models/responses/base'

class AccountRepository {
    async index (): Promise<PaginatedResponse<Account>> {
        const response = await HttpClient.getJson<IPaginatedResponse<IAccount>>(Api.index)

        return PaginatedResponse.fromInterface(response, (dto): Account => {
            return new Account(dto)
        })
    }

    async testImap (account: Account): Promise<boolean> {
        try {
            const response = await HttpClient.getJson<BaseResponse>(Api.testImap(account))

            return response.status === 'success'
        } catch (e) {
            return false
        }
    }

    async testSmtp (account: Account): Promise<boolean> {
        try {
            const response = await HttpClient.getJson<BaseResponse>(Api.testSmtp(account))

            return response.status === 'success'
        } catch (e) {
            return false
        }
    }

    async gmailRedirect (account: Account): Promise<string> {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const response = await HttpClient.getJson<{ redirect_url: string }>(Api.gmailRedirect(account))

        return response.redirect_url
    }

    async update (account: Account): Promise<IAccount> {
        return await HttpClient.patchJson<IAccount>(Api.update(account.id), account)
    }
}

export default new AccountRepository()
