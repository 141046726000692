// eslint-disable-next-line
const pageLogin = (): Promise<any> => import('@/views/Auth/PageLogin.vue')

// eslint-disable-next-line
const pageSettings = (): Promise<any> => import('@/views/PageSettings.vue')

// eslint-disable-next-line
const pageMessagesList = (): Promise<any> => import('@/views/Messages/PageMessagesList.vue')

// eslint-disable-next-line
const pageMessageWrite = (): Promise<any> => import('@/views/Messages/PageMessageWrite.vue')

// eslint-disable-next-line
const pageSorter = (): Promise<any> => import('@/views/PageSorter.vue')

// eslint-disable-next-line
const pageTemplates = (): Promise<any> => import('@/views/PageTemplates.vue')

const routes =  [
    { path: '/login', name: 'auth.login', component: pageLogin, meta: { guest: true } },
    { path: '/', name: 'home', component: pageMessagesList, meta: { auth: true } },
    { path: '/settings', name: 'settings', component: pageSettings, meta: { auth: true } },
    { path: '/messages', name: 'messages.list', component: pageMessagesList, meta: { auth: true } },
    { path: '/messages/:messageId/:mode(reply|forward)', name: 'messages.answer', component: pageMessageWrite, meta: { auth: true } },
    { path: '/messages/new', name: 'messages.new', component: pageMessageWrite, meta: { auth: true } },
    { path: '/sorters', name: 'sorters', component: pageSorter, meta: { auth: true } },
    { path: '/templates', name: 'templates', component: pageTemplates, meta: { auth: true } }
]

export default routes
