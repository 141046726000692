export default {
    contains_phrase: 'Zawiera frazę',
    folder: 'Folder',
    from: 'Od',
    has_not_tags: 'Nie zawiera etykiet',
    has_tags: 'Zawiera etykiety',
    login: 'Login kontrahenta',
    must_have_attachment: 'Musi zawierać załącznik',
    must_have_note: 'Musi zawierać notatkę',
    not_contains_phrase: 'Nie zawiera frazy',
    received_within: 'Otrzymane w ciągu',
    start_typing_to_search: 'Zacznij pisać, by wyszukać',
    subject: 'Temat',
    to: 'Do',
    only_unseen: 'Pokaż tylko nieprzeczytane',
    search_in_text: 'Szukaj w treści',
    deep_search: {
        enable: 'Szukaj starszych',
        hint_enabled: 'Uruchomiono już głębokie przeszukiwanie.<br>Wiadomość, którą próbujesz znaleźć, niestety nie figuruje w naszej bazie danych.',
        hint_enable: 'Standardowo system ogranicza wyszukiwania do ostatniego pół roku.<br>Uruchom wyszukiwanie w pełnym zakresie.'
    }
}
