import { Message } from '@/models/data/message'
import HttpClient from '@/lib/http-client'
import { trading as Api } from '@/api'
import { Customer } from '@/models/data/customer'
import { Order } from '@/models/data/order'

class TradingRepository {
    async assignContractor (message: Message, customer: Customer): Promise<void> {
        const request = {
            nickname: customer.nickname
        }

        return await HttpClient.postJson(Api.assignContractor(message), request)
    }

    async assignOrder (message: Message, order: Order): Promise<void> {
        const request = {
            order_id: order.order_number
        }

        return await HttpClient.postJson(Api.assignOrder(message), request)
    }

    async getMessagesWithContractor (message: Message): Promise<Message[]> {
        return await HttpClient.getJson<Message[]>(`${Api.messagesWithContractor}?nickname=${message.details.contractor.nickname}`)
    }
}

export default new TradingRepository()
