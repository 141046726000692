import { RuleParamSchema } from 'vee-validate/dist/types/types'

const validateSize = (file: File, width: number, height: number): Promise<boolean> => {
    return new Promise(resolve => {
        const image = new Image()
        image.onerror = () => resolve(false)
        image.onload = () => resolve(image.width >= width && image.height >= height)

        image.src = URL.createObjectURL(file)
    })
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const validate = (files: File | Array<File> | undefined | null, params: Record<string, any>): Promise<boolean> => {
    if (files === undefined || files === null) {
        return Promise.resolve(false)
    }

    files = Array.isArray(files) ? files : [files]

    const regex = /\.(jpg|svg|jpeg|png|bmp|gif)$/i
    const images = []

    for (let i = 0; i < files.length; i++) {
        if (!regex.test(files[i].name)) {
            return Promise.resolve(false)
        }

        images.push(files[i])
    }

    return Promise.all(images.map(image => validateSize(image, params.width, params.height))).then(values => {
        return values.every(v => v)
    })
}

const params: RuleParamSchema[] = [
    {
        name: 'width',
        cast (value: string): number {
            return Number(value)
        }
    },
    {
        name: 'height',
        cast (value: string): number {
            return Number(value)
        }
    }
]

export default {
    validate,
    params
}
