import { IIdentifiable, ITimestamped, Status } from '@/models/models'
import { Account } from '@/models/data/account'
import { MessageFlagName, SorterMatchingMode } from '@/api/enums'

interface IActionValue {
    flag_name: MessageFlagName | null
    flag_value: boolean | null
    target_path: { path: string } | null
}

class ActionValue {
    flag_name: MessageFlagName | null = null
    flag_value: boolean | null = null
    target_path: { path: string } | null = null

    constructor (data?: IActionValue) {
        if (data !== undefined) {
            this.flag_name = data.flag_name
            this.flag_value = data.flag_value
            this.target_path = data.target_path
        }
    }
}

interface IAction extends IIdentifiable {
    name: string
    value: IActionValue
}

class Action implements IAction {
    id = 0
    name = ''
    value = new ActionValue()

    constructor (data?: IAction) {
        if (data !== undefined) {
            this.id = data.id
            this.name = data.name
            this.value = new ActionValue(data.value)
        }
    }
}

interface IRule extends IIdentifiable {
    field: string
    operator: string
    value: string
}

class Rule implements IRule {
    id = 0
    field = ''
    operator = ''
    value = ''

    constructor (data?: IRule) {
        if (data !== undefined) {
            this.id = data.id
            this.field = data.field
            this.operator = data.operator
            this.value = data.value
        }
    }
}

interface ISorter extends IIdentifiable, ITimestamped {
    name: string
    description: string
    is_active: boolean
    matching_mode: SorterMatchingMode
    actions_count: number
    rules_count: number
    account: Account
    actions: Array<Action>
    rules: Array<Rule>,
    status: Status
}

class Sorter implements ISorter {
    id = 0
    name = ''
    description = ''
    is_active = false
    matching_mode = SorterMatchingMode.ALL
    actions_count = 0
    rules_count = 0
    account = new Account()
    actions = new Array<Action>()
    rules = new Array<Rule>()
    status = new Status()
    created_at = ''
    updated_at = ''

    constructor (data?: ISorter) {
        if (data !== undefined) {
            this.id = data.id
            this.name = data.name
            this.description = data.description
            this.is_active = data.is_active
            this.matching_mode = data.matching_mode
            this.actions_count = data.actions_count
            this.rules_count = data.rules_count
            this.account = data.account
            this.actions = data.actions ? data.actions.map(action => new Action(action)) : []
            this.rules = data.rules ? data.rules.map(rule => new Rule(rule)) : []
            this.created_at = data.created_at
            this.updated_at = data.updated_at
        }
    }
}

export {
    ISorter,
    Sorter,

    IAction,
    Action,

    IRule,
    Rule
}
