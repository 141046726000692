enum MailProtocol {
    SMTP,
    IMAP
}

enum MessageFlagName {
    ANSWERED = 'answered',
    DELETED = 'deleted',
    DRAFT = 'draft',
    FLAGGED = 'flagged',
    RECENT = 'recent',
    SEEN = 'seen'
}

enum ContentMode {
    MAIN,
    VIEW
}

enum SorterMatchingMode {
    ALL = 'all',
    ONE = 'one'
}

enum MessageWriteMode {
    NEW = 'new',
    FORWARD = 'forward',
    REPLY = 'reply'
}

export {
    MailProtocol, MessageFlagName, ContentMode, SorterMatchingMode, MessageWriteMode
}
