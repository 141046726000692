import { Customer, ICustomer } from '@/models/data/customer'
import client from '@/lib/http-client'
import { customers as Api } from '@/api'

class CustomerRepository {
    async search (query: string): Promise<Array<Customer>> {
        query ??= ''
        const request = 'query=' + query
        const response = await client.getJson<Array<ICustomer>>(Api.search + '?' + request)

        response.map((dto: ICustomer): Customer => new Customer(dto))

        return response
    }
}

export default new CustomerRepository()
