import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { TemplatesModuleState } from '@/store/state'
import { PaginatedResponse } from '@/models/data/pagination/paginated-response'
import { Param, Template } from '@/models/data/template'
import { Item, Order } from '@/models/data/order'
import { Customer } from '@/models/data/customer'
import NotFoundError from '@/errors/not-found-error'
import TemplateRepository from '@/repositories/template-repository'
import Mode from '@/store/mode'
import CustomerRepository from '@/repositories/customer-repository'
import OrderRepository from '@/repositories/order-repository'

@Module({ name: 'templates', namespaced: true })
export default class Templates extends VuexModule implements TemplatesModuleState {
    private static errorTag = 'TemplatesStoreModule'

    entries = new PaginatedResponse<Template>()
    params = new Array<Param>()
    customers = new Array<Customer>()
    orders = new Array<Order>()

    selectedCustomer: Customer | null = null
    selectedOrder: Order | null = null
    selectedOrderItem: Item | null = null

    mode: Mode = Mode.CREATE

    @Mutation
    setEntries (data: PaginatedResponse<Template>) {
        this.entries = data
    }

    @Mutation
    setParams (params: Array<Param>): void {
        this.params = params
    }

    @Mutation
    setCustomers (customers: Array<Customer>): void {
        this.customers = customers
    }

    @Mutation
    setOrders (orders: Array<Order>): void {
        this.orders = orders
    }

    @Mutation
    setSelectedCustomer (customer: Customer): void {
        this.selectedCustomer = customer
    }

    @Mutation
    setSelectedOrder (order: Order): void {
        this.selectedOrder = order
    }

    @Mutation
    setSelectedOrderItem (item: Item): void {
        this.selectedOrderItem = item
    }

    @Mutation
    setMode (mode: Mode) {
        this.mode = mode
    }

    @Action
    async loadPage (params = { page: 1, paginate: 20 }) {
        const data = await TemplateRepository.index(params.page, params.paginate)

        if (data.meta.current_page > data.meta.last_page) {
            throw new NotFoundError(Templates.errorTag)
        }

        this.setEntries(data)
    }

    @Action
    async loadParams (): Promise<void> {
        const data = await TemplateRepository.getParams()

        this.setParams(data)
    }

    @Action
    async save (template: Template): Promise<void> {
        if (this.mode === Mode.CREATE) {
            await TemplateRepository.store(template)
        }

        if (this.mode === Mode.EDIT) {
            await TemplateRepository.update(template)
        }

        await this.loadPage()
    }

    @Action
    async remove (template: Template): Promise<void> {
        await TemplateRepository.remove(template)

        await this.loadPage()
    }

    @Action
    async searchCustomers (query: string): Promise<void> {
        const data = await CustomerRepository.search(query)
        this.setCustomers(data)
    }

    @Action
    async searchOrders (params: { orderNumber?: string, customer?: Customer }): Promise<void> {
        const data = await OrderRepository.search(params.orderNumber, params.customer)
        this.setOrders(data)
    }
}
