interface IIdentifiable {
    id: number
}

interface ITimestamped {
    created_at: string
    updated_at: string
}

interface IPart {
    processing: boolean,
    success: boolean | null,
    message: string | null
}

interface IStatus {
    saving: IPart
    loading: IPart
    removing: IPart
}

class Part implements IPart {
    message = null
    processing = false
    success = null
}

class Status implements IStatus {
    loading: IPart = new Part
    removing: IPart = new Part
    saving: IPart = new Part

    reset (): void {
        this.loading = new Part
        this.removing = new Part
        this.saving = new Part
    }

    startRemoving (): void {
        this.removing.processing = true
    }

    successRemoving (): void {
        this.removing.processing = false
        this.removing.success = true
    }

    errorRemoving (message: string | null = null): void {
        this.removing.processing = false
        this.removing.success = false
        this.removing.message = message
    }

    startLoading (): void {
        this.loading.processing = true
    }

    successLoading (): void {
        this.loading.processing = false
        this.loading.success = true
    }

    errorLoading (message: string | null = null): void {
        this.loading.processing = false
        this.loading.success = false
        this.loading.message = message
    }
}

export type {
    IIdentifiable,
    ITimestamped,
    IStatus
}

export {
    Status
}
