import Vue from 'vue'
import { UserVuetifyPreset } from 'vuetify'
import pl from 'vuetify/src/locale/pl'
import Vuetify, {
    VAlert,
    VBtn,
    VCol,
    VDivider,
    VExpandTransition,
    VForm,
    VIcon,
    VRow,
    VSlideXReverseTransition,
    VSpacer,
    VToolbar,
    VToolbarTitle,
    VTooltip
} from 'vuetify/lib'

const config: Partial<UserVuetifyPreset> = {
    lang: {
        locales: {
            pl
        },
        current: 'pl'
    },
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
        dark: false,
        options: {
            customProperties: true,
            minifyTheme (css: string): string {
                return process.env.NODE_ENV === 'production'
                    ? css.replace(/[\r\n|\r|\n]/g, '')
                    : css
            }
        }
    }
}

Vue.use(Vuetify, {
    components: {
        VAlert,
        VBtn,
        VCol,
        VDivider,
        VExpandTransition,
        VForm,
        VIcon,
        VRow,
        VSlideXReverseTransition,
        VSpacer,
        VToolbar,
        VToolbarTitle,
        VTooltip
    }
})

export default new Vuetify(config)
