import { Action, VuexModule } from 'vuex-module-decorators'

abstract class BaseModule extends VuexModule {
    @Action
    startLoading (): void {
        this.context.commit('status/startLoading', null, { root: true })
    }

    @Action
    async stopLoading (): Promise<void> {
        await this.context.dispatch('status/stopLoading', null, { root: true })
    }

    @Action
    resetLoading (): void {
        this.context.commit('status/resetLoading', null, { root: true })
    }

    @Action
    startBigLoading (): void {
        this.context.commit('status/startBigLoading', null, { root: true })
    }

    @Action
    async stopBigLoading (): Promise<void> {
        await this.context.dispatch('status/stopBigLoading', null, { root: true })
    }

    @Action
    resetBigLoading (): void {
        this.context.commit('status/resetBigLoading', null, { root: true })
    }
}

export default BaseModule
