
    import Vue from 'vue'

    import { Component } from 'vue-property-decorator'
    import { getModule } from 'vuex-module-decorators'
    import Location from '@/store/modules/location'

    import Accounts from '@/store/modules/accounts'
    import { Account } from '@/models/data/account'
    import { Folder, Status } from '@/models/data/folder'

    import Notifications from '@/lib/notifications'
    import Mode from '@/store/mode'
    import Messages from '@/store/modules/messages'
    import { Message } from '@/models/data/message'
    import { isEqual } from 'lodash'
    import Interface from '@/store/modules/interface'

    @Component
    export default class ComponentFoldersTree extends Vue {
        accountModule = getModule(Accounts, this.$store)
        locationModule = getModule(Location, this.$store)
        messagesModule = getModule(Messages, this.$store)
        interfaceModule = getModule(Interface, this.$store)

        expandedAccounts: number[] = [0,1]
        renameDialog = false
        createDialog = false
        saving = false
        dragEntered: { account: Account, folder: Folder } | null = null
        contextMenu: {
            isVisible: boolean
            x: number
            y: number
            account: Account
            folder: Folder
        } = {
            isVisible: false,
            x: 0,
            y: 0,
            account: new Account(),
            folder: new Folder()
        }

        operations = [
            {
                title: this.$t('crud.edit'),
                operation: this.rename
            },
            {
                title: this.$t('crud.create'),
                operation: this.add
            },
            {
                title: this.$t('crud.delete'),
                operation: this.remove
            }
        ]

        reset (): void {
            this.messagesModule.setFolder()
            this.messagesModule.search()
        }

        allReceivedMode (): void {
            this.messagesModule.setFolder({
                folder: new Folder({
                    folders: [],
                    name: 'INBOX',
                    path: 'INBOX',
                    account: new Account(),
                    status: new Status()
                })
            })
            this.messagesModule.search()
        }

        getProperty<V extends keyof Folder> (key: V): Folder[V] {
            if (this.accountModule.mode === Mode.CREATE) {
                return this.accountModule.newFolderEntry[key]
            } else {
                return this.accountModule.selectedFolderEntry[key]
            }
        }

        showContextMenu (e: MouseEvent, account: Account, folder: Folder): void {
            e.preventDefault()
            this.contextMenu.isVisible = false
            this.contextMenu.x = e.clientX
            this.contextMenu.y = e.clientY
            this.contextMenu.account = account
            this.contextMenu.folder = folder
            this.$nextTick(() => {
                this.contextMenu.isVisible = true
            })
        }

        setDragEntered (account: Account, folder: Folder): void {
            this.dragEntered = { account, folder }
        }

        async onDrop (event: DragEvent, folder: Folder, account: Account): Promise<void> {
            this.interfaceModule.startLoading()
            const dataTransfer = event.dataTransfer as DataTransfer
            const items: Array<string> = dataTransfer.getData('items').split(',')
            const messages: Array<Message> = this.messages.filter((message, index) => items.includes(index.toString()))

            if (dataTransfer.effectAllowed === 'move') {
                await this.messagesModule.move({ messages, folder, account }).then(async () => {
                    await this.messagesModule.search()
                    Notifications.success(this.$t('messages.success.move') as string)
                }).catch(() => {
                    Notifications.error(this.$t('messages.error.move') as string)
                })
            }

            this.dragEntered = null
            this.interfaceModule.stopLoading()
        }

        setLocation (account: Account, folder: Folder): void {
            this.locationModule.changeLocation({ account, folder })
            this.messagesModule.setFolder({ account, folder })
            this.messagesModule.search()
            this.messagesModule.setSelectedMessages([])
        }

        isAccountSelected (account: Account): boolean {
            return this.messagesModule.searchParams.account === account
        }

        isFolderSelected (folder: Folder): boolean {
            return this.messagesModule.searchParams.folder === folder
        }

        isFolderDragEntered (account: Account, folder: Folder): boolean {
            return isEqual(this.dragEntered, { account, folder })
        }

        rename (): void {
            this.renameDialog = true
            this.accountModule.setSelectedFolderEntry(this.contextMenu.folder)
        }

        add (): void {
            this.createDialog = true
            this.accountModule.setNewFolderEntry({ account: this.contextMenu.account, folder: this.contextMenu.folder })
            this.accountModule.setMode(Mode.CREATE)
        }

        get messages (): Array<Message> {
            return this.messagesModule.entries.data
        }

        get accounts (): Array<Account> {
            return this.accountModule.entries.data
        }

        get name (): string {
            return this.getProperty('name')
        }

        set name (value: string) {
            this.accountModule.setFolderProperty({ key: 'name', value })
        }

        get isAllAccountMode (): boolean {
            return this.messagesModule.searchParams.account === undefined
                && this.messagesModule.searchParams.folder === undefined
        }

        get isAllReceivedMode (): boolean {
            return (
                this.messagesModule.searchParams.account === undefined
                || isEqual(this.messagesModule.searchParams.account, new Account())
            )
                && this.messagesModule.searchParams.folder?.name === 'INBOX'
        }

        async update (): Promise<void> {
            this.saving = true
            try {
                await this.accountModule.renameFolder(this.contextMenu.account)
                this.renameDialog = false
                this.accountModule.setSelectedFolderEntry(new Folder())
                Notifications.success(this.$t('crud.success.updated') as string)
            } catch (e) {
                Notifications.error(this.$t('crud.error.update') as string)
            }
            this.saving = false
        }

        async remove (): Promise<void> {
            await this.accountModule.removeFolder({ account: this.contextMenu.account, folder: this.contextMenu.folder })
                .then(() => {
                    Notifications.success(this.$t('crud.success.deleted') as string)
                })
                .catch(() => {
                    Notifications.error(this.$t('crud.error.delete') as string)
                })
        }

        async create (): Promise<void> {
            this.saving = true
            await this.accountModule.createFolder(this.contextMenu.account)
                .then(() => {
                    Notifications.success(this.$t('crud.success.created') as string)
                    this.createDialog = false
                })
                .catch(() => {
                    Notifications.error(this.$t('crud.error.create') as string)
                })
            this.saving = false
        }

        onEnd (e: CustomEvent): void {
            console.log(e)
        }

        onMove (evt: { added: { element: { name: string }, newIndex: number } }): boolean {
            console.log(evt)
            return false
        }
    }
