import { IIdentifiable } from '@/models/models'
import { IPaginationLinks, PaginationLinks } from '@/models/data/pagination/pagination-links'
import { IPaginationMeta, PaginationMeta } from '@/models/data/pagination/pagination-meta'

type DtoConverter<U extends IIdentifiable, V extends U> = (dataDto: U) => V

interface IPaginatedResponse<T> {
    data: Array<T>
    links: IPaginationLinks
    meta: IPaginationMeta
}

class PaginatedResponse<T> implements IPaginatedResponse<T> {
    data: Array<T> = []
    links = new PaginationLinks()
    meta = new PaginationMeta()

    copyFrom (data: PaginatedResponse<T>): void {
        this.data = data.data
        this.links = data.links
        this.meta = data.meta
    }

    mergeWith (data: PaginatedResponse<T>): void {
        this.data.push(...data.data)
        this.links = data.links
        this.meta = data.meta
    }

    setData (data: Array<T>): void {
        this.data = data
    }

    public static fromInterface<U extends IIdentifiable, V extends U> (
        dto: IPaginatedResponse<U>,
        converter: DtoConverter<U, V>
    ): PaginatedResponse<V> {
        const response = new PaginatedResponse<V>()

        response.data = dto.data.map(entryDto => converter(entryDto))
        response.links = new PaginationLinks(dto.links)
        response.meta = new PaginationMeta(dto.meta)

        return response
    }
}

export {
    IPaginatedResponse,
    PaginatedResponse
}
