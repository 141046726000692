import { Payload } from 'vuex'

import { AsyncStorage, VuexPersistOptions } from '@/store/plugins/vuex-persist/types'
import { VuexPersistError } from '@/store/plugins/vuex-persist/vuex-persist-error'

class Options<S extends Record<string, unknown>, T = Partial<S>> implements VuexPersistOptions<S, T> {
    public asyncStorage = false
    public key = 'vuex'
    public storage!: Storage | AsyncStorage
    public strictMode = false

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public filter = (mutation: Payload): boolean => true
    public reducer = (state: S): S | T => state

    /**
     * Update defaults with options provided by user
     *
     * @param options
     */
    update (options: VuexPersistOptions<S, T>): void {
        if (options.asyncStorage !== undefined) {
            this.asyncStorage = options.asyncStorage
        }

        if (options.key !== undefined) {
            this.key = options.key
        }

        if (options.strictMode !== undefined) {
            this.strictMode = options.strictMode
        }

        if (options.filter !== undefined) {
            this.filter = options.filter
        }

        if (options.reducer !== undefined) {
            this.reducer = options.reducer
        }

        this.updateStorage(options)
    }

    /**
     * Try different options for storage and update defaults
     *
     * @param options
     * @private
     */
    private updateStorage (options: VuexPersistOptions<S, T>): void {
        let localStorageAvailable = true

        try {
            window.localStorage.getItem('')
        } catch (e) {
            localStorageAvailable = false
        }

        if (options.storage !== undefined) {
            this.storage = options.storage
        } else if (localStorageAvailable) {
            this.storage = window.localStorage
        } else {
            throw new VuexPersistError('No storage available')
        }
    }
}

export {
    Options
}
