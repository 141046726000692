import { Account } from '@/models/data/account'

interface IStatus {
    exists: number | null
    recent: number | null
    uidvalidity: number | null
    uidnext: number | null
    unseen: number | null
}

class Status implements IStatus {
    exists: number | null = null
    recent: number | null = null
    uidnext: number | null = null
    uidvalidity: number | null = null
    unseen: number | null = null

    constructor (data?: IStatus) {
        if (data !== undefined) {
            this.exists = data.exists
            this.recent = data.recent
            this.uidvalidity = data.uidvalidity
            this.uidnext = data.uidnext
            this.unseen = data.unseen
        }
    }
}

interface IFolder {
    name: string
    path: string
    folders: Array<IFolder> | null
    // children: Array<IFolder> | null
    account: Account,
    status: Status
}

class Folder implements IFolder {
    name = ''
    path = ''
    folders: Array<IFolder> | null = null
    // children: Array<IFolder> | null = null
    account: Account = new Account()
    status: Status = new Status()

    constructor (data?: IFolder) {
        if (data !== undefined) {
            this.name = data.name
            this.path = data.path
            this.folders = data.folders
            // this.children = data.children // field added not to break backward compatibility after adding sorters.
            this.account = data.account
            this.status = new Status(data.status)
        }
    }
}

export {
    IFolder,
    Folder,
    Status
}
