import '@/bootstrap'

import Vue from 'vue'

import App from '@/App.vue'

import vuetify from '@/plugins/vuetify'
import '@/components/index'

import router from '@/router'
import store from '@/store/store'
import i18n from '@/lang'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'

import '@/validate'
import '@/http'
import '@/styles'
import '@/lib/notifications'

Vue.config.productionTip = false

Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'mdiSvg'
})

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')
