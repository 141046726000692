import { Tag } from '@/models/data/tag'
import { Folder, Status } from '@/models/data/folder'
import { Account } from '@/models/data/account'

export class MessagesSearchParams {
    from?: string
    to?: string
    subject?: string
    contractorNickname?: string
    hasTags?: Array<Tag>
    hasNotTags?: Array<Tag>
    containsPhrase?: string
    notContainsPhrase?: string
    mustHaveAttachment?: boolean
    mustHaveNote?: boolean
    receivedWithin?: number
    sortBy? = 'data.date'
    sortDesc = true
    account?: Account
    // By default, show only received messages (from INBOX folder) not together received and sent.
    folder?: Folder = new Folder({ name: 'INBOX', path: 'INBOX', folders: [], account: new Account(), status: new Status() })
    onlyUnseen = false
    searchInText = false
    deepSearch = false
    page = 1
}
