
    import Vue from 'vue'
    import { Component, Watch } from 'vue-property-decorator'
    import ComponentFoldersTree from '@/components/messages/ComponentFoldersTree.vue'
    import ComponentNavigation from '@/components/layout/ComponentNavigation.vue'
    import { getModule } from 'vuex-module-decorators'
    import Interface from '@/store/modules/interface'
    import Store from '@/store/store'
    import { PersistedStore } from '@/store/plugins/vuex-persist/types'
    import { StoreState } from '@/store/state'
    import Accounts from '@/store/modules/accounts'
    import Tags from '@/store/modules/tags'
    import Auth from '@/store/modules/auth'

    @Component({
        components: { ComponentFoldersTree, ComponentNavigation }
    })
    export default class App extends Vue {
        authModule = getModule(Auth, this.$store)
        interfaceModule = getModule(Interface, this.$store)

        get isLoading (): boolean {
            return this.interfaceModule.loading
        }

        get isSidebarVisible (): boolean {
            return getModule(Interface, this.$store).isSidebarVisible
        }

        set isSidebarVisible (value: boolean) {
            if (value) {
                this.interfaceModule.showSidebar()
            } else {
                this.interfaceModule.hideSidebar()
            }
        }

        get isMessagesList (): boolean {
            return this.$route.name === 'messages.list'
        }

        get isLogged (): boolean {
            return this.authModule.isLogged
        }

        async init (): Promise<void> {
            this.interfaceModule.startLoading()
            if (this.authModule.isLogged) {
                const accountsModule = getModule(Accounts, this.$store)
                // const foldersModule = getModule(Folders, this.$store)
                const tagsModule = getModule(Tags, this.$store)

                await accountsModule.loadPage()
                await accountsModule.loadFolders()

                await tagsModule.loadEntries()
            }
            this.interfaceModule.stopLoading()
        }

        async mounted (): Promise<void> {
            await (Store as PersistedStore<StoreState>).restored
            await this.authModule.checkIsLogged()

            if (!this.authModule.isLogged && this.$router.currentRoute.name !== 'auth.login') {
                this.$router.push({ name: 'auth.login' })
            }

            await this.init()
        }

        @Watch('isLogged')
        onLogged (): void {
            if (this.authModule.isLogged) {
                this.init()
            }
        }
    }
