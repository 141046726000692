import HttpClient from '@/lib/http-client'
import { notes as Api } from '@/api'
import { Message } from '@/models/data/message'
import { INote } from '@/models/data/note'

class NoteRepository {
    async update (message: Message): Promise<INote> {
        return await HttpClient.patchJson<INote>(Api.set(message), { note: message.details.note.note })
    }
}

export default new NoteRepository()
