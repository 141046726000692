import { IIdentifiable, ITimestamped } from '@/models/models'
import { Folder } from '@/models/data/folder'

interface IImapConfiguration {
    host: string
    port: number
    protocol: string
    encryption: string
    validate_cert: boolean
    username: string
    password: string
    authentication: string
}

interface ISmtpConfiguration {
    host: string
    port: number
    use_ssl: boolean
    username: string
    password: string
    encryption: string
    authentication_method: string
    signature: {
        username: string
        mail: string
    }
}

interface ISignature {
    name: string
    mail: string
}

interface ITestResults {
    imap: boolean | null
    smtp: boolean | null
}

interface IAccount extends IIdentifiable, ITimestamped {
    name: string
    description: string | null
    imap_configuration: IImapConfiguration | null
    smtp_configuration: ISmtpConfiguration | null
    signature: ISignature | null
    loading: { test: { imap: boolean, smtp: boolean } }
    is_active: boolean
    folders: Array<Folder> | null
    test_results: ITestResults
}

class ImapConfiguration implements IImapConfiguration {
    host = ''
    port = 0
    protocol = ''
    encryption = ''
    validate_cert = false
    username = ''
    password = ''
    authentication = ''

    constructor (data?: IImapConfiguration) {
        if (data !== undefined) {
            this.host = data.host || ''
            this.port = data.port || 0
            this.protocol = data.protocol || ''
            this.encryption = data.encryption || ''
            this.validate_cert = data.validate_cert || false
            this.username = data.username || ''
            this.password = data.password || ''
            this.authentication = data.authentication || ''
        }
    }
}

class SmtpConfiguration implements ISmtpConfiguration {
    host = ''
    port = 0
    use_ssl = true
    username = ''
    password = ''
    encryption = ''
    authentication_method = ''
    signature = {
        username: '',
        mail: ''
    }

    constructor (data?: ISmtpConfiguration) {
        if (data !== undefined) {
            this.host = data.host || ''
            this.port = data.port || 0
            this.use_ssl = data.use_ssl || true
            this.username = data.username || ''
            this.password = data.password || ''
            this.encryption = data.encryption || ''
            this.authentication_method = data.authentication_method || ''
            this.signature = data.signature || {
                username: '',
                mail: ''
            }
        }
    }
}

class Signature implements ISignature {
    mail = ''
    name = ''

    constructor (data?: ISignature) {
        if (data !== undefined) {
            this.mail = data.mail || ''
            this.name = data.name || ''
        }
    }
}

class Account implements IAccount {
    id = 0
    name = ''
    description: string | null = null
    imap_configuration: ImapConfiguration = new ImapConfiguration()
    smtp_configuration: SmtpConfiguration = new SmtpConfiguration()
    signature: Signature = new Signature()
    test_results: ITestResults = { imap: null, smtp: null }
    loading = { test: { imap: false, smtp: false } }
    is_active = false
    folders: Array<Folder> | null = null
    created_at = ''
    updated_at = ''

    constructor (data?: IAccount) {
        if (data !== undefined) {
            this.id = data.id
            this.name = data.name
            this.description = data.description
            this.imap_configuration = new ImapConfiguration(data.imap_configuration ?? undefined)
            this.smtp_configuration = new SmtpConfiguration(data.smtp_configuration ?? undefined)
            this.signature = new Signature(data.signature ?? undefined)
            this.is_active = data.is_active
            this.folders = data.folders ? data.folders.map(folder => new Folder(folder)) : null
            this.created_at = data.created_at
            this.updated_at = data.updated_at
        }
    }

    // mapFolders (folders: Array<any>): Array<Folder> {
    // todo(@szymekjanaczek) one day we have to create recursive method to map all folders.
    // }
}

export {
    IAccount, IImapConfiguration, ISmtpConfiguration,
    Account, ImapConfiguration, SmtpConfiguration
}
