import { RuleParamSchema } from 'vee-validate/dist/types/types'

/* eslint-disable @typescript-eslint/no-explicit-any */
const validate = (value: string | string[], params: Record<string, any> = {}): boolean => {
    // https://gist.github.com/dperini/729294
    // eslint-disable-next-line
    const re = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i

    if (params.multiple && !Array.isArray(value)) {
        value = String(value)
            .split(',')
            .map(urlStr => urlStr.trim())
    }

    if (Array.isArray(value)) {
        return value.every(val => re.test(String(val)))
    }

    return re.test(String(value))
}

const params: RuleParamSchema[] = [
    {
        name: 'multiple',
        default: false
    }
]

export default {
    validate,
    params
}
