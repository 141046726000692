class AuthError extends Error implements Error {
    name = 'AuthError'
}

class LoginError extends Error implements Error {
    name = 'LoginError'
    messages: Array<string> = []

    constructor (messages: Array<string>) {
        super()
        this.messages = messages
    }
}

export { AuthError, LoginError }
