
    import Vue from 'vue'
    import Component from 'vue-class-component'
    import { Prop } from 'vue-property-decorator'

    @Component
    export default class ComponentBaseCrudForm extends Vue {
        @Prop({ type: Boolean, required: true }) editMode!: boolean
        @Prop({ type: Boolean, required: true }) valid!: boolean
        @Prop({ type: Boolean, required: true }) saving!: boolean
    }
