import HttpClient from '@/lib/http-client'
import {
    IAuthenticatedUserData,
    IJwtAuthenticationData,
    IJwtResponse,
    IUser,
    IUserCredentials
} from '@/models/data/user'
import { BaseResponse } from '@/models/responses/base'
import { AuthError } from '@/errors/auth-errors'

import { auth as Api } from '@/api'

class AuthRepository {
    async login (credentials: IUserCredentials): Promise<IJwtAuthenticationData> {
        const jwtAuthResponse = await HttpClient.postJson<IJwtResponse>(Api.login, {
            email: credentials.email,
            password: credentials.password
        })

        HttpClient.authorize(jwtAuthResponse.token_type, jwtAuthResponse.access_token)
        let userData: IAuthenticatedUserData

        try {
            userData = await this.userData()
        } catch (e) {
            HttpClient.unauthorize()
            throw new AuthError()
        }

        return {
            status: jwtAuthResponse.status,
            access_token: jwtAuthResponse.access_token,
            token_type: jwtAuthResponse.token_type,
            expires_in: jwtAuthResponse.expires_in,

            login_time: Date.now(),
            data: userData
        }
    }

    // noinspection JSMethodCanBeStatic
    async logout (): Promise<boolean> {
        try {
            const response = await HttpClient.postJson<BaseResponse>(Api.logout)

            if (response.status !== 'success') {
                return false
            }

            HttpClient.unauthorize()

            return true
        } catch (err) {
            HttpClient.unauthorize()

            return true
        }
    }

    async userData (): Promise<IAuthenticatedUserData> {
        const response = await HttpClient.getJson<IUser>(Api.data)

        return {
            id: response.id,
            email: response.email,
            name: response.name
        }
    }

    async checkStatus (): Promise<boolean> {
        try {
            await HttpClient.getJson<BaseResponse>(Api.data)

            return true
        } catch (err) {
            return false
        }
    }
}

export default new AuthRepository()
