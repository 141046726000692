
    import Vue from 'vue'
    import { Component } from 'vue-property-decorator'
    import { getModule } from 'vuex-module-decorators'
    import Messages from '@/store/modules/messages'
    import Tags from '@/store/modules/tags'
    import { Tag } from '@/models/data/tag'
    import Notifications from '@/lib/notifications'

    @Component
    export default class ComponentSearch extends Vue {
        tagsModule = getModule(Tags, this.$store)
        messagesModule = getModule(Messages, this.$store)
        dateRanges: Array<{ text: string, value: number }> = [
            { text: 'Ostatnie 24 godziny', value: 24 },
            { text: 'Ostatnie 7 dni', value: 24 * 7 },
            { text: 'Ostatnie 30 dni', value: 24 * 30 },
            { text: 'Ostatnie 90 dni', value: 24 * 90 },
            { text: 'Ostatnie 365 dni', value: 24 * 365 },
        ]

        get tags (): Array<Tag> {
            return this.tagsModule.entries
        }

        // region search params
        get onlyUnseen (): boolean {
            return this.messagesModule.searchParams.onlyUnseen ?? ''
        }

        set onlyUnseen (value: boolean) {
            this.messagesModule.setOnlyUnseen(value)
        }

        get from (): string {
            return this.messagesModule.searchParams.from ?? ''
        }

        set from (value: string) {
            this.messagesModule.setFrom(value)
        }

        get searchingInText (): boolean {
            return this.messagesModule.searchParams.searchInText ?? false
        }

        set searchingInText (value: boolean) {
            this.messagesModule.setSearchInText(value)
        }

        get to (): string {
            return this.messagesModule.searchParams.to ?? ''
        }

        set to (value: string) {
            this.messagesModule.setTo(value)
        }

        get contractorNickname (): string {
            return this.messagesModule.searchParams.contractorNickname ?? ''
        }

        set contractorNickname (value: string) {
            this.messagesModule.setContractorNickname(value)
        }

        get containsPhrase (): string {
            return this.messagesModule.searchParams.containsPhrase ?? ''
        }

        set containsPhrase (value: string) {
            this.messagesModule.setContainsPhrase(value)
        }

        get notContainsPhrase (): string {
            return this.messagesModule.searchParams.notContainsPhrase ?? ''
        }

        set notContainsPhrase (value: string) {
            this.messagesModule.setNotContainsPhrase(value)
        }

        get hasTags (): Array<Tag> {
            return this.messagesModule.searchParams.hasTags ?? []
        }

        set hasTags (value: Array<Tag>) {
            this.messagesModule.setHasTags(value)
        }

        get hasNotTags (): Array<Tag> {
            return this.messagesModule.searchParams.hasNotTags ?? []
        }

        set hasNotTags (value: Array<Tag>) {
            this.messagesModule.setHasNotTags(value)
        }

        get receivedWithin (): number {
            return this.messagesModule.searchParams.receivedWithin ?? 0
        }

        set receivedWithin (value: number) {
            this.messagesModule.setReceivedWithin(value)
        }

        get subject (): string {
            return this.messagesModule.searchParams.subject ?? ''
        }

        set subject (value: string) {
            this.messagesModule.setSubject(value)
        }

        get mustHaveNote (): boolean {
            return this.messagesModule.searchParams.mustHaveNote ?? false
        }

        set mustHaveNote (value: boolean) {
            this.messagesModule.setMustHaveNote(value)
        }

        get mustHaveAttachment (): boolean {
            return this.messagesModule.searchParams.mustHaveAttachment ?? false
        }

        set mustHaveAttachment (value: boolean) {
            this.messagesModule.setMustHaveAttachment(value)
        }

        get deepSearch (): boolean {
            return this.messagesModule.searchParams.deepSearch ?? false
        }

        set deepSearch (value: boolean) {
            this.messagesModule.setDeepSearch(value)
        }

        get sortBy (): string {
            return this.messagesModule.searchParams.sortBy ?? ''
        }

        set sortBy (value: string) {
            this.messagesModule.setSortBy(value)
        }

        get sortDesc (): boolean {
            return this.messagesModule.searchParams.sortDesc ?? false
        }

        set sortDesc (value: boolean) {
            this.messagesModule.setSortDesc(value)
        }
        // endregion

        async searchOnlyUnseen (): Promise<void> {
            this.onlyUnseen = !this.onlyUnseen
            await this.search()
        }

        async searchInText (): Promise<void> {
            this.searchingInText = !this.searchingInText
            await this.search()
        }

        async clear (): Promise<void> {
            this.containsPhrase = ''
            this.searchingInText = false
            this.deepSearch = false
            await this.search()
        }

        async search (): Promise<void> {
            this.messagesModule.startMessagesLoading()
            try {
                await this.messagesModule.search()
                this.messagesModule.setUsedDeepSearch(false)
            } catch (e) {
                Notifications.error(this.$t('messages.error.search') as string)
            }
            this.messagesModule.stopMessagesLoading()
        }
    }
