import HttpClient from '@/lib/http-client'
import { IPaginatedResponse, PaginatedResponse } from '@/models/data/pagination/paginated-response'
import { Action, ISorter, Rule, Sorter } from '@/models/data/sorter'
import { sorters as Api } from '@/api'
import { Method } from '@/repositories/abstract/method'

class SorterRepository {
    async index (): Promise<PaginatedResponse<Sorter>> {
        const response = await HttpClient.getJson<IPaginatedResponse<ISorter>>(Api.index)

        return PaginatedResponse.fromInterface(response, (dto: ISorter): Sorter => {
            return new Sorter(dto)
        })
    }

    async store (sorter: Sorter): Promise<Sorter> {
        const request = {
            name: sorter.name,
            description: sorter.description,
            account_id: sorter.account.id,
            matching_mode: sorter.matching_mode,
            is_active: sorter.is_active,
            rules: sorter.rules,
            actions: sorter.actions
        }

        const response = await HttpClient.postJson<ISorter>(Api.store, request)

        return new Sorter(response)
    }

    async update (sorter: Sorter): Promise<Sorter> {
        const actions = sorter.actions.map((action: Action): any => {
            return {
                id: action.id,
                name: action.name,
                value: {
                    flag_name: action.value.flag_name,
                    flag_value: action.value.flag_value,
                    target_path: action.value.target_path?.path ?? null
                }
            }
        })

        const request = {
            name: sorter.name,
            description: sorter.description,
            account_id: sorter.account?.id,
            matching_mode: sorter.matching_mode,
            is_active: sorter.is_active,
            rules: sorter.rules,
            actions: actions
        }

        const response = await HttpClient.patchJson<ISorter>(Api.update(sorter.id), request)

        return new Sorter(response)
    }

    async run (sorter: Sorter): Promise<void> {
        const request = {
            sorter_ids: [sorter.id],
            account_ids: [sorter.account.id],
            since_date: '2022-01-01'
        }

        await HttpClient.postJson(Api.run, request)
    }

    async remove (sorter: Sorter): Promise<void> {
        await HttpClient.delete(Api.remove(sorter.id))
    }

    async activate (sorter: Sorter): Promise<void> {
        await HttpClient.patch(Api.activate(sorter.id))
    }

    async deactivate (sorter: Sorter): Promise<void> {
        await HttpClient.patch(Api.deactivate(sorter.id))
    }

    modelToRequestUrl (model: Sorter, method: Method): URLSearchParams {
        const urlSearchParams = new URLSearchParams()

        if (method === Method.Update) {
            urlSearchParams.append('_method', 'PATCH')
        }

        if (model.name) {
            urlSearchParams.append('name', model.name)
        }

        if (model.description) {
            urlSearchParams.append('description', model.description)
        }

        if (model.is_active) {
            urlSearchParams.append('is_active', model.is_active.toString())
        }

        if (model.matching_mode) {
            urlSearchParams.append('matching_mode', model.matching_mode)
        }

        if (model.account) {
            urlSearchParams.append('account_id', model.account.id.toString())
        }

        if (model.actions) {
            model.actions.map((action: Action, i: number) => {
                urlSearchParams.append(`actions[${i}][name]`, action.id.toString())
                if (['move', 'copy'].includes(action.name)) {
                    /**
                     * We can make this assignment because we know that the value can never be null in this case.
                     */
                    const targetPath = action.value.target_path?.path as string
                    urlSearchParams.append(`actions[${i}][value][target_path][path]`, targetPath)
                }

                if (action.name === 'mark') {
                    /**
                     * We can make this assignment because we know that these values can never be null in this case.
                     */
                    const flagName = action.value.flag_name as string
                    const flagValue = action.value.flag_value as boolean
                    urlSearchParams.append(`actions[${i}][value][flag_name]`, flagName)
                    urlSearchParams.append(`actions[${i}][value][flag_value]`, flagValue.toString())
                }
            })
        }

        if (model.rules) {
            model.rules.map((rule: Rule, i: number) => {
                urlSearchParams.append(`rules[${i}][field]`, rule.field)
                urlSearchParams.append(`rules[${i}][operator]`, rule.operator)
                urlSearchParams.append(`rules[${i}][value]`, rule.value)
            })
        }

        return urlSearchParams
    }
}

export default new SorterRepository()
