import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { InterfaceModuleState } from '@/store/state'

@Module({ name: 'interface', namespaced: true })
export default class Interface extends VuexModule implements InterfaceModuleState {
    sidebarVisibility = false
    loading = false

    @Mutation
    showSidebar (): void {
        this.sidebarVisibility = true
    }

    @Mutation
    hideSidebar (): void {
        this.sidebarVisibility = false
    }

    @Mutation
    startLoading (): void {
        this.loading = true
    }

    @Mutation
    stopLoading (): void {
        this.loading = false
    }

    get isLoading (): boolean {
        return this.loading
    }

    get isSidebarVisible (): boolean {
        return this.sidebarVisibility
    }

    @Action
    toggleSidebarVisibility (): void {
        if (this.sidebarVisibility) {
            this.hideSidebar()
        } else {
            this.showSidebar()
        }
    }
}
