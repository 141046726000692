import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router'

import Users from '@/store/modules/auth'

export default <T>(store: Store<T>): NavigationGuard => {
    /**
     * Check if destination route is designed for guest users
     * and if so, check if user is logged in.
     * If user is logged in - go to the next navigation guard.
     * If not               - redirect user to the login page.
     *
     * @param to
     * @param from
     * @param next
     */
    return async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
        if (to.meta?.guest !== true) {
            if (!getModule(Users, store).isLogged) {
                next({ name: 'auth.login' })
            } else {
                next()
            }
        } else {
            next()
        }
    }
}
