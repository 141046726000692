import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { LocationModuleState, ISearchParams } from '@/store/state'
import { Account } from '@/models/data/account'
import { Folder } from '@/models/data/folder'
import { Tag } from '@/models/data/tag'

interface ILocation {
    account: Account
    folder: Folder
}

export interface IFlagParam {
    name: string
    value: boolean
}

@Module({ name: 'location', namespaced: true })
export default class Location extends VuexModule implements LocationModuleState {
    account: Account | null = null
    folder: Folder | null = null
    searchParams: ISearchParams = {
        query: '',
        field: '',
        flag: null,
        tags: null
    }

    @Mutation
    setLocation (payload: ILocation): void {
        this.account = payload.account
        this.folder = payload.folder
    }

    @Mutation
    setQuery (query: string): void {
        this.searchParams.query = query
    }

    @Mutation
    setField (field: string): void {
        this.searchParams.field = field
    }

    @Mutation
    setFlag (payload: IFlagParam | null): void {
        this.searchParams.flag = payload
    }

    @Mutation
    setTags (tags: Array<Tag> | null): void {
        this.searchParams.tags = tags
    }

    @Mutation
    reset (): void {
        this.searchParams = {
            query: '',
            field: '',
            flag: null,
            tags: null
        }
    }

    @Action
    changeLocation (payload: ILocation): void {
        // todo(@szymekjanaczek) write messages reloading call!
        this.setLocation(payload)
    }
}
