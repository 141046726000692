import { Action, Module, Mutation } from 'vuex-module-decorators'
import BaseModule from '@/store/modules/base-module'
import { MessagesModuleState } from '@/store/state'
import { PaginatedResponse } from '@/models/data/pagination/paginated-response'
import MessageRepository from '@/repositories/message-repository'
import NotFoundError from '@/errors/not-found-error'
import { Attachment, IMessageFlag, Message } from '@/models/data/message'
import { Tag } from '@/models/data/tag'
import { MessagesSearchParams } from '@/models/data/messages-search-params'
import Mode from '@/store/mode'
import { cloneDeep } from 'lodash'
import NoteRepository from '@/repositories/note-repository'
import { Folder } from '@/models/data/folder'
import { Account } from '@/models/data/account'
import TagRepository from '@/repositories/tag-repository'
import TradingRepository from '@/repositories/trading-repository'
import { Customer } from '@/models/data/customer'
import { Order } from '@/models/data/order'

@Module({ name: 'messages', namespaced: true })
export default class Messages extends BaseModule implements MessagesModuleState {
    private static errorTag = 'MessagesStoreModule'

    /**
     * List of searching params.
     */
    searchParams = new MessagesSearchParams()

    /**
     * The current page of messages.
     */
    entries = new PaginatedResponse<Message>()

    /**
     * List of messages connected with message in watchingMessage property.
     */
    threadMessages: Array<Message> = []

    /**
     * List of selected messages - on main list (not threads).
     */
    selectedMessages: Array<Message> = []

    /**
     * Main Message loading indicator. When user change currently watching message by clicking on the another row in the
     * messages list, this variable is set to true and stays in this state until new message fully loaded.
     */
    watchingMessageLoading = false
    threadWatchingMessageLoading = false

    /**
     * Contains currently watching (MAIN) message.
     */
    watchingEntry: Message = new Message()

    /**
     * Contains currently watching message from main message's thread.
     */
    threadWatchingEntry: Message = new Message()

    /**
     * CRUD purposes - currently mode, use with selectedEntry and newEntry properties.
     */
    mode: Mode = Mode.EDIT

    /**
     * CRUD purposes. Contains selected message while single I/O operation is performing on it - e.g. updating its note.
     */
    selectedEntry: Message = new Message()

    /**
     * Legacy, not used now - will be needed with CRUD.
     */
    newEntry: Message = new Message()

    loading = false
    intersectVisibility = false
    usedDeepSearch = false

    threadsLoading = true

    /**
     * Set list of messages.
     * @param payload
     */
    @Mutation
    setEntries (payload: PaginatedResponse<Message>): void {
        this.entries = payload
    }

    /**
     * Add new messages to list of them. Useful on infinite scroll, when we don't want to load only one page at once,
     * but have to add messages to previously generated list, and so on.
     * @param payload
     */
    @Mutation
    addEntries (payload: PaginatedResponse<Message>): void {
        this.entries.data = [...this.entries.data, ...payload.data]
        this.entries.meta.current_page = payload.meta.current_page
        this.entries.meta.to = payload.meta.to
    }

    @Mutation
    startMessagesLoading (): void {
        this.loading = true
    }

    @Mutation
    stopMessagesLoading (): void {
        this.loading = false
    }

    @Mutation
    startThreadsLoading (): void {
        this.threadsLoading = true
    }

    @Mutation
    stopThreadsLoading (): void {
        this.threadsLoading = false
    }

    @Mutation
    showIntersect (): void {
        this.intersectVisibility = true
    }

    @Mutation
    hideIntersect (): void {
        this.intersectVisibility = false
    }

    @Mutation
    setDeepSearch (payload: boolean): void {
        this.searchParams.deepSearch = payload
    }

    @Mutation
    setUsedDeepSearch (payload: boolean): void {
        this.usedDeepSearch = payload
    }

    /**
     * Remove messages from list. Useful on messages deleting CRUD operation.
     * @param payload
     */
    @Mutation
    removeEntries (payload: Array<Message>): void {
        this.entries.data = this.entries.data.filter(entry => !payload.includes(entry))
    }

    @Mutation
    setSelectedEntry (message: Message): void {
        this.selectedEntry = cloneDeep(message)
    }

    @Mutation
    setSelectedMessages (messages: Array<Message>): void {
        this.selectedMessages = messages
    }

    @Mutation
    setWatchingEntry (message: Message): void {
        this.watchingEntry = message
    }

    @Mutation
    setThreadWatchingEntry (message?: Message): void {
        if (message === undefined) {
            message = new Message()
        }

        this.threadWatchingEntry = message
    }

    @Mutation
    setMode (mode: Mode): void {
        this.mode = mode
    }

    @Mutation
    startWatchingMessageLoading (): void {
        this.watchingMessageLoading = true
    }

    @Mutation
    stopWatchingMessageLoading (): void {
        this.watchingMessageLoading = false
    }

    @Mutation
    startThreadWatchingMessageLoading (): void {
        this.threadWatchingMessageLoading = true
    }

    @Mutation
    stopThreadWatchingMessageLoading (): void {
        this.threadWatchingMessageLoading = false
    }

    @Mutation
    setNote (note: string): void {
        this.selectedEntry.details.note.note = note
    }

    @Mutation
    massUpdateFlag (flag: IMessageFlag): void {
        this.selectedMessages.map((message: Message) => {
            message.data.flags[flag.name] = flag.value
        })
    }

    @Mutation
    setFlag (payload: { message: Message, flag: IMessageFlag }): void {
        payload.message.data.flags[payload.flag.name] = payload.flag.value
    }

    // region search params
    @Mutation
    setOnlyUnseen (payload: boolean): void {
        this.searchParams.onlyUnseen = payload
    }

    @Mutation
    setTo (payload: string): void {
        this.searchParams.to = payload
    }

    @Mutation
    setFrom (payload: string): void {
        this.searchParams.from = payload
    }

    @Mutation
    setSearchInText (payload: boolean): void {
        this.searchParams.searchInText = payload
    }

    @Mutation
    setContractorNickname (payload: string): void {
        this.searchParams.contractorNickname = payload
    }

    @Mutation
    setContainsPhrase (payload: string): void {
        this.searchParams.containsPhrase = payload
    }

    @Mutation
    setNotContainsPhrase (payload: string): void {
        this.searchParams.notContainsPhrase = payload
    }

    @Mutation
    setHasTags (payload: Array<Tag>): void {
        this.searchParams.hasTags = payload
    }

    @Mutation
    setHasNotTags (payload: Array<Tag>): void {
        this.searchParams.hasNotTags = payload
    }

    @Mutation
    setReceivedWithin (payload: number): void {
        this.searchParams.receivedWithin = payload
    }

    @Mutation
    setSubject (payload: string): void {
        this.searchParams.subject = payload
    }

    @Mutation
    setMustHaveNote (payload: boolean): void {
        this.searchParams.mustHaveNote = payload
    }

    @Mutation
    setMustHaveAttachment (payload: boolean): void {
        this.searchParams.mustHaveAttachment = payload
    }

    @Mutation
    setSortBy (payload: string): void {
        this.searchParams.sortBy = payload
    }

    @Mutation
    setSortDesc (payload: boolean): void {
        this.searchParams.sortDesc = payload
    }

    @Mutation
    setAccount (payload?: Account): void {
        this.searchParams.account = payload
    }

    @Mutation
    setFolder (payload?: { account?: Account, folder: Folder }): void {
        this.searchParams.account = payload?.account
        this.searchParams.folder = payload?.folder
    }

    @Mutation
    setPage (payload: number): void {
        this.searchParams.page = payload
    }
    // endregion

    @Mutation
    setAssignedTag (tag: Tag): void {
        this.selectedMessages.map((message: Message) => {
            if (message.details.labels.filter(assignedTag => assignedTag.id === tag.id).length <= 0) {
                message.details.labels.push(tag)
            }
        })
    }

    @Mutation
    setUnassignedTag (tag: Tag): void {
        this.selectedMessages.map((message: Message) => {
            message.details.labels.splice(message.details.labels.indexOf(tag), 1)
        })
    }

    @Mutation
    setWatchingMessageAttachments (payload: Array<Attachment>): void {
        this.watchingEntry.attachments = payload
    }

    @Mutation
    setThreadMessages (payload: Array<Message>): void {
        this.threadMessages = payload
    }

    @Mutation
    setAssignedContractor (payload: string): void {
        this.watchingEntry.details.contractor = { nickname: payload }
    }

    @Mutation
    setAssignedOrder (payload: string): void {
        this.watchingEntry.details.order = { order_id: payload, is_confirmed: true }
    }

    @Mutation
    startMessageLoading (payload: Message): void {
        payload.loading = true
    }

    @Mutation
    stopMessageLoading (payload: Message): void {
        payload.loading = false
    }

    @Action
    async search (): Promise<void> {
        this.setSelectedMessages([])
        this.setPage(1)
        const data = await MessageRepository.search(this.searchParams)

        if (data.meta.current_page > data.meta.last_page) {
            throw new NotFoundError(Messages.errorTag)
        }

        this.setEntries(data)
    }

    @Action
    async loadNextPage (): Promise<void> {
        const data = await MessageRepository.search(this.searchParams)

        if (data.meta.current_page > data.meta.last_page) {
            throw new NotFoundError(Messages.errorTag)
        }

        this.addEntries(data)
    }

    @Action
    async assignTags (tags: Array<Tag>): Promise<void> {
        await Promise.all(tags.map(async (tag: Tag) => {
            await TagRepository.assign(this.selectedMessages, tag)
            this.setAssignedTag(tag)
        }))
    }

    @Action
    async unassignTags (tags: Array<Tag>): Promise<void> {
        await Promise.all(tags.map(async (tag: Tag) => {
            await TagRepository.unassign(this.selectedMessages, tag)
            this.setUnassignedTag(tag)
        }))
    }

    @Action
    async massMark (flag: IMessageFlag): Promise<void> {
        await MessageRepository.massMark(this.selectedMessages, flag)
        this.massUpdateFlag(flag)
    }

    @Action
    async markMessage (payload: { message: Message, flag: IMessageFlag }): Promise<void> {
        await MessageRepository.massMark([payload.message], payload.flag)
        this.setFlag(payload)
    }

    @Action
    async massDelete (): Promise<void> {
        await MessageRepository.massDelete(this.selectedMessages)
        this.removeEntries(this.selectedMessages)
    }

    @Action
    async move (payload: { messages: Array<Message>, folder: Folder, account: Account }): Promise<void> {
        await MessageRepository.move(payload.messages, payload.folder, payload.account)

        await this.search()
    }

    @Action
    async copy (payload: { messages: Array<Message>, folder: Folder, account: Account }): Promise<void> {
        await MessageRepository.copy(payload.messages, payload.folder, payload.account)

        await this.search()
    }

    @Action
    async updateNote (message?: Message): Promise<void> {
        const selectedMessage = message || this.activeEntry
        await NoteRepository.update(selectedMessage)
        await this.search()
    }

    @Action
    async loadWatchingMessageDetails (message: Message): Promise<void> {
        this.startMessageLoading(message)
        this.setWatchingEntry(message)
        this.startThreadsLoading()
        if (this.watchingEntry.details.contractor.nickname) {
            const threadMessages = await TradingRepository.getMessagesWithContractor(message)
            this.setThreadMessages(threadMessages)
            this.stopThreadsLoading()
        }
        this.stopMessageLoading(message)

        const attachments = await MessageRepository.getAttachments(message)
        this.setWatchingMessageAttachments(attachments)
    }

    @Action
    async loadThreadWatchingMessageDetails (message: Message): Promise<void> {
        this.setThreadWatchingEntry(message)
    }

    @Action
    async assignContractor (customer: Customer): Promise<void> {
        console.log(this.watchingEntry)
        await TradingRepository.assignContractor(this.watchingEntry, customer)
        this.setAssignedContractor(customer.nickname)
    }

    @Action
    async assignOrder (order: Order): Promise<void> {
        await TradingRepository.assignOrder(this.watchingEntry, order)
        this.setAssignedOrder(order.order_number)
    }

    get activeEntry (): Message {
        if (this.mode === Mode.EDIT) {
            return this.selectedEntry
        } else {
            return this.newEntry
        }
    }
}
