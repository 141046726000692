import { Plugin } from 'vuex'

import { VuexPersistOptions } from '@/store/plugins/vuex-persist/types'
import { VuexPersistDelegate } from '@/store/plugins/vuex-persist/delegates/delegate'

import { AsyncStorageDelegate } from '@/store/plugins/vuex-persist/delegates/async-storage-delegate'
import { StorageDelegate } from '@/store/plugins/vuex-persist/delegates/storage-delegate'
import { Options } from '@/store/plugins/vuex-persist/options'

class VuexPersist<S extends Record<string, unknown>, T = Partial<S>> implements VuexPersistDelegate<S, T> {
    private delegate: VuexPersistDelegate<S, T>
    private options: Options<S, T> = new Options()

    public constructor (options: VuexPersistOptions<S, T>) {
        this.options.update(options)

        if (this.options.asyncStorage) {
            this.delegate = new AsyncStorageDelegate(this.options)
        } else {
            this.delegate = new StorageDelegate(this.options)
        }
    }

    public restoreState (key: string): S | Promise<S> {
        return this.delegate.restoreState(key)
    }

    public saveState (key: string, state: S | T): void | Promise<S | T> {
        return this.delegate.saveState(key, state)
    }

    get plugin (): Plugin<S> {
        return this.delegate.plugin
    }
}

export {
    VuexPersist
}

export { RESTORE_MUTATION } from '@/store/plugins/vuex-persist/restore-mutation'
