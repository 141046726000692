export default {
    account_settings: 'Ustawienia konta',
    all_accounts: 'Wszystkie konta',
    attribute: 'Atrybut',
    cancel: 'Anuluj',
    clear: 'Wyczyść',
    create: 'Stwórz',
    external_apps_settings: 'Ustawienia zewnętrznych aplikacji',
    flag: 'Flaga',
    logout: 'Wyloguj',
    mailboxes_settings: 'Ustawienia skrzynek pocztowych',
    messages: 'Wiadomości',
    phrase: 'Fraza',
    search: 'Szukaj',
    settings: 'Ustawienia',
    show_folders: 'Pokaż listę folderów',
    sorter: 'Sorter',
    templates: 'Szablony',
    tag: 'Znacznik',
    tags_settings: 'Ustawienia etykiet',
    edit: 'Edytuj',
    loading: 'Ładowanie',
    no_data: 'Brak danych',
    save_changes: 'Zapisz zmiany',
    download: 'Pobierz',
    open_in_new_tab: 'Otwórz w nowej karcie',
    refresh_list: 'Odśwież listę',
    assign: 'Przypisz',
    unassign: 'Usuń przypisanie',
    pagination: {
        pagination: 'Paginacja',
        pages_summary: '1 - {to} z {total}'
    }
}
