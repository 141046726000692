interface IPaginationLinks {
    first: string
    last: string
    next: string | null
    prev: string | null
}

class PaginationLinks implements IPaginationLinks {
    first = ''
    last = ''
    next: string | null = null
    prev: string | null = null

    constructor (dto?: IPaginationLinks) {
        if (dto) {
            this.first = dto.first
            this.last = dto.last
            this.next = dto.next
            this.prev = dto.prev
        }
    }
}

export {
    IPaginationLinks,
    PaginationLinks
}
