import { Store } from 'vuex'
import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router'

import { PersistedStore } from '@/store/plugins/vuex-persist/types'

export default <T>(store: Store<T>): NavigationGuard => {
    /**
     * Wait for the store to be loaded from async storage.
     * This is designed to avoid race condition that can occur
     * when data will not be loaded from the store yet
     * and the app wants to load data from it.
     * For example if persisted store contains information about user
     * that is logged in it will break the UI without this guard.
     *
     * ==== WARNING ====
     * This route guard should be always FIRST!
     * ==== WARNING ====
     *
     * @param to
     * @param from
     * @param next
     */
    return async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
        await (store as PersistedStore<T>).restored
        next()
    }
}
