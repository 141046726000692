import { HttpError } from '@magiczne/http-client'
import { Action, Module, Mutation } from 'vuex-module-decorators'

import { IAuthenticatedUserData, IJwtAuthenticationData, IUserCredentials } from '@/models/data/user'
import { AuthModuleState } from '@/store/state'
import { LoginError } from '@/errors/auth-errors'
import BaseModule from '@/store/modules/base-module'

import AuthRepository from '@/repositories/auth-repository'

@Module({ name: 'auth', namespaced: true })
export default class Auth extends BaseModule implements AuthModuleState {
    authenticatedUser: IJwtAuthenticationData | null = null
    credentials: IUserCredentials = {
        email: '',
        password: ''
    }

    @Mutation
    setAuthenticatedUser (payload: IJwtAuthenticationData): void {
        this.authenticatedUser = payload
    }

    @Mutation
    setEmail (payload: string): void {
        this.credentials.email = payload
    }

    @Mutation
    setPassword (payload: string): void {
        this.credentials.password = payload
    }

    @Mutation
    unsetAuthenticatedUser (): void {
        this.authenticatedUser = null
    }

    @Action
    async checkTokenExpiration (): Promise<void> {
        if (this.authenticatedUser !== null) {
            if (this.authenticatedUser.login_time + this.authenticatedUser.expires_in * 1000 < Date.now()) {
                this.unsetAuthenticatedUser()
            } else {
                await this.refreshData()
            }
        }
    }

    @Action
    async login (): Promise<void> {
        this.startBigLoading()

        try {
            this.setAuthenticatedUser(await AuthRepository.login(this.credentials))
        } catch (err) {
            if (err instanceof Error && HttpError.isHttpError(err) && err.response !== undefined) {
                const json = await err.response.json()

                throw new LoginError(json.errors ?? [])
            }

            throw err
        } finally {
            this.context.commit('status/decrementLoading', null, { root: true })
            this.setPassword('')
        }
    }

    @Action
    async logout (): Promise<void> {
        if (await AuthRepository.logout()) {
            this.unsetAuthenticatedUser()
        }
    }

    @Action
    async checkIsLogged (): Promise<void> {
        const isLogged = await AuthRepository.checkStatus()

        if (!isLogged) {
            this.unsetAuthenticatedUser()
        }
    }

    get authHeader (): string {
        return this.authenticatedUser !== null
            ? `${this.authenticatedUser.token_type} ${this.authenticatedUser.access_token}`
            : ''
    }

    @Action
    async refreshData (): Promise<void> {
        if (this.authenticatedUser) {
            const userData = await AuthRepository.userData()

            this.setAuthenticatedUser({
                ...this.authenticatedUser,
                data: userData
            })
        }
    }

    get isLogged (): boolean {
        return this.authenticatedUser !== null
    }

    get user (): IAuthenticatedUserData | undefined {
        return this.authenticatedUser === null ? undefined : this.authenticatedUser.data
    }
}
