import { IPaginatedResponse, PaginatedResponse } from '@/models/data/pagination/paginated-response'
import { IParam, ITemplate, Param, Template } from '@/models/data/template'
import client from '@/lib/http-client'
import { templates as Api } from '@/api'

class TemplateRepository {
    async index (page = 1, paginate = 20): Promise<PaginatedResponse<Template>> {
        const request = new URLSearchParams()
        request.append('page', page.toString())
        request.append('paginate', paginate.toString())

        const response = await client.getJson<IPaginatedResponse<ITemplate>>(`${Api.index}?${request.toString()}`)

        return PaginatedResponse.fromInterface(response, (dto: ITemplate): Template => {
            return new Template(dto)
        })
    }

    async store (template: Template): Promise<void> {
        const request = {
            name: template.name,
            subject: template.subject,
            content: template.content,
        }

        await client.postJson(Api.store, request)
    }

    async update (template: Template): Promise<void> {
        const request = {
            name: template.name,
            subject: template.subject,
            content: template.content,
        }

        await client.patchJson(Api.update(template.id), request)
    }

    async remove (template: Template): Promise<void> {
        await client.deleteJson(Api.remove(template.id))
    }

    async getParams (): Promise<Array<Param>> {
        const response = await client.getJson<Array<IParam>>(Api.getParams)

        response.map((dto: IParam): Param => new Param(dto))

        return response
    }
}

export default new TemplateRepository()
