import HttpClient from '@/lib/http-client'
import { messages as Api } from '@/api'
import { Message, IMessage, IMessageFlag, Attachment, IDraft } from '@/models/data/message'
import { IPaginatedResponse, PaginatedResponse } from '@/models/data/pagination/paginated-response'
import { MessagesSearchParams } from '@/models/data/messages-search-params'
import { Method } from '@/repositories/abstract/method'
import { Tag } from '@/models/data/tag'
import { Folder } from '@/models/data/folder'
import { Account } from '@/models/data/account'

class MessageRepository {
    async search (searchParams?: MessagesSearchParams): Promise<PaginatedResponse<Message>> {
        let query = ''
        if (searchParams) {
            query = '?' + this.modelToRequestUrl(searchParams, Method.Get).toString()
        }

        const response = await HttpClient.getJson<IPaginatedResponse<IMessage>>(Api.search + query)

        return PaginatedResponse.fromInterface(response, (dto: IMessage): Message => {
            return new Message(dto)
        })
    }

    async show (id: number | string): Promise<Message> {
        const response = await HttpClient.getJson<IMessage>(Api.show(id))

        return new Message(response)
    }

    async move (messages: Array<Message>, folder: Folder, account: Account): Promise<void> {
        const request = {
            message_ids: messages.map(m => m.id),
            new_path: folder.path
        }

        return await HttpClient.patchJson(Api.mass.move(account), request)
    }

    async copy (messages: Array<Message>, folder: Folder, account: Account): Promise<void> {
        const request = {
            message_ids: messages.map(m => m.id),
            new_path: folder.path
        }

        return await HttpClient.patchJson(Api.mass.copy(account), request)
    }

    async massMark (messages: Array<Message>, flag: IMessageFlag): Promise<void> {
        const request = {
            message_ids: messages.map(m => m.id),
            flag: {
                name: flag.name,
                is_enabled: flag.value
            }
        }

        return await HttpClient.patchJson(Api.mass.mark, request)
    }

    async massDelete (messages: Array<Message>): Promise<void> {
        const request = {
            message_ids: messages.map(m => m.id),
        }

        return await HttpClient.postJson(Api.mass.delete, request)
    }

    async getAttachments (message: Message): Promise<Array<Attachment>> {
        return await HttpClient.getJson<Array<Attachment>>(Api.getAttachments(message))
    }

    async send (draft: IDraft): Promise<Response> {
        const formData = new FormData()

        formData.append('subject', draft.subject)
        formData.append('to', draft.to)
        formData.append('body', draft.body)
        formData.append('mode', draft.mode)

        if (draft.previousMessage !== null) {
            formData.append('previous_message_id', draft.previousMessage.id.toString())
        }

        for (const attachment of draft.attachments) {
            formData.append('attachments[]', attachment)
        }

        return await HttpClient.postJson(Api.send(draft.from), formData)
    }

    modelToRequestUrl (model: MessagesSearchParams, method: Method): URLSearchParams {
        const urlSearchParams = new URLSearchParams()

        if (method === Method.Update) {
            urlSearchParams.append('_method', 'PATCH')
        }

        if (model.onlyUnseen) {
            urlSearchParams.append('filter[only_unseen]', model.onlyUnseen.toString())
        }

        if (model.from) {
            urlSearchParams.append('filter[from]', model.from)
        }

        if (model.to) {
            urlSearchParams.append('filter[to]', model.to)
        }

        if (model.subject) {
            urlSearchParams.append('filter[subject]', model.subject)
        }

        if (model.contractorNickname) {
            urlSearchParams.append('filter[contractor_nickname]', model.contractorNickname)
        }

        if (model.hasTags) {
            urlSearchParams.append('filter[has_tags]', model.hasTags.map((tag: Tag) => tag.id).join(','))
        }

        if (model.hasNotTags) {
            urlSearchParams.append('filter[has_not_tags]', model.hasNotTags.map((tag: Tag) => tag.id).join(','))
        }

        if (model.containsPhrase) {
            urlSearchParams.append('filter[contains_phrase]', model.containsPhrase)
        }

        if (model.notContainsPhrase) {
            urlSearchParams.append('filter[not_contains_phrase]', model.notContainsPhrase)
        }

        if (model.mustHaveAttachment) {
            urlSearchParams.append('filter[must_have_attachment]', model.mustHaveAttachment ? '1' : '0')
        }

        if (model.mustHaveNote) {
            urlSearchParams.append('filter[must_have_note]', model.mustHaveNote ? '1' : '0')
        }

        if (model.receivedWithin) {
            urlSearchParams.append('filter[received_within]', model.receivedWithin.toString())
        }

        if (model.folder) {
            urlSearchParams.append('filter[path]', model.folder.path)
        }

        if (model.account) {
            urlSearchParams.append('filter[account_id]', model.account.id.toString())
        }

        if (model.searchInText) {
            urlSearchParams.append('options[search_in_text]', model.searchInText ? '1' : '0')
        }

        if (model.deepSearch) {
            urlSearchParams.append('options[deep_search]', model.deepSearch ? '1' : '0')
        }

        if (model.sortBy) {
            const field = model.sortDesc ? '-' + model.sortBy : model.sortBy
            urlSearchParams.append('sort', field)
        }

        urlSearchParams.append('page', model.page.toString())

        return urlSearchParams
    }
}

export default new MessageRepository()
