import { RuleParamSchema } from 'vee-validate/dist/types/types'

/* eslint-disable @typescript-eslint/no-explicit-any */
const validate = (files: File | Array<File> | undefined | null, params: Record<string, any>): boolean => {
    if (isNaN(params.size)) {
        return false
    }

    const nSize = params.size * 1024

    if (Array.isArray(files)) {
        return !files.some(file => file.size > nSize)
    }

    if (files !== undefined && files !== null) {
        return files.size <= nSize
    }

    return false
}

const params: RuleParamSchema[] = [
    {
        name: 'size',
        cast (value: string): number {
            return Number(value)
        }
    }
]

export default {
    validate,
    params
}
