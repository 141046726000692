import HttpClient from '@/lib/http-client'
import { tags as Api } from '@/api'
import { ITag, Tag } from '@/models/data/tag'
import { Method } from '@/repositories/abstract/method'
import { Message } from '@/models/data/message'

class TagRepository {
    async index (): Promise<Tag[]> {
        return await HttpClient.getJson<ITag[]>(Api.index)
    }

    async create (newTag: Tag): Promise<Tag> {
        const request = this.modelToRequestData(newTag, Method.Create)
        return await HttpClient.postJson<ITag>(Api.store, request)
    }

    async update (tag: Tag): Promise<Tag> {
        return await HttpClient.patchJson<ITag>(Api.update(tag.id), tag)
    }

    async remove (tag: Tag): Promise<void> {
        return await HttpClient.deleteJson(Api.remove(tag.id))
    }

    async assign (messages: Array<Message>, tag: Tag): Promise<void> {
        const request = {
            message_ids: messages.map(message => message.id)
        }

        return await HttpClient.patchJson(Api.assign(tag), request)
    }
    async unassign (messages: Array<Message>, tag: Tag): Promise<void> {
        const request = {
            message_ids: messages.map(message => message.id)
        }

        return await HttpClient.patchJson(Api.unassign(tag), request)
    }

    modelToRequestData (model: Tag, method: Method): Partial<Tag> | FormData {
        const formData = new FormData()

        if (method === Method.Update) {
            formData.append('_method', 'PATCH')
        }

        formData.append('name', model.name)
        formData.append('color', model.color)

        return formData
    }
}

export default new TagRepository()
