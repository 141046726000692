import { Customer } from '@/models/data/customer'
import { Order, IOrder } from '@/models/data/order'
import client from '@/lib/http-client'
import { orders as Api } from '@/api'

class OrderRepository {
    async search (orderNumber?: string, customer?: Customer): Promise<Array<Order>> {
        const request = new URLSearchParams

        if (orderNumber !== undefined && orderNumber !== '' && orderNumber !== null) {
            request.append('order_number', orderNumber)
        }

        if (customer !== undefined && customer !== null) {
            request.append('buyer_user_nickname', customer?.nickname)
        }

        const response = await client.getJson<Array<IOrder>>(Api.search + '?' + request)

        response.map((dto: IOrder): Order => new Order(dto))

        return response
    }
}

export default new OrderRepository()
