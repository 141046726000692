import { Env } from '@/env/index'

let env!: Env

if (process.env.NODE_ENV === 'production') {
    env = {
        apiBasePath: 'https://api.bms.szymonjanaczek.net.pl/', // with trailing slash!
        basePath: 'https://api.bms.szymonjanaczek.net.pl',
        pusherKey: '',
        googleAnalyticsId: '',
        sentryDsn: '',
        ninjasFactsApiKey: 'aU0fqSe0O3kPi0W4/0cnFg==ZBpgzORQ5w6vgwwE'
    }
} else {
    env = {
        apiBasePath: 'http://api.bms.loc/', // with trailing slash!
        basePath: 'http://localhost:8080',
        pusherKey: '',
        googleAnalyticsId: '',
        sentryDsn: '',
        ninjasFactsApiKey: 'aU0fqSe0O3kPi0W4/0cnFg==ZBpgzORQ5w6vgwwE'
    }
}

export default env
