import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { StatusModuleState } from '@/store/state'

@Module({ name: 'status', namespaced: true })
export default class Status extends VuexModule implements StatusModuleState {
    loadingCount = 0
    bigLoadingCount = 0

    @Action
    stopLoading (): void {
        // Timeout to remove blinking progressbar behavior
        setTimeout(() => {
            this.decrementLoading()
        }, 500)
    }

    @Action
    stopBigLoading (): void {
        // Timeout to remove blinking progressbar behavior
        setTimeout(() => {
            this.decrementBigLoading()
        }, 500)
    }

    @Mutation
    startLoading (): void {
        this.loadingCount++
    }

    @Mutation
    decrementLoading (): void {
        if (this.loadingCount > 0) {
            this.loadingCount--
        }
    }

    @Mutation
    resetLoading (): void {
        this.loadingCount = 0
    }

    @Mutation
    startBigLoading (): void {
        this.bigLoadingCount++
    }

    @Mutation
    decrementBigLoading (): void {
        if (this.bigLoadingCount > 0) {
            this.bigLoadingCount--
        }
    }

    @Mutation
    resetBigLoading (): void {
        this.bigLoadingCount = 0
    }

    get isLoading (): boolean {
        return this.loadingCount > 0
    }

    get isBigLoading (): boolean {
        return this.bigLoadingCount > 0
    }
}
