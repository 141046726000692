import { getBaseCrudApi } from '@/api/generators'
import { Account } from '@/models/data/account'
import { Message } from '@/models/data/message'
import { Tag } from '@/models/data/tag'

const auth = {
    sanctumCsrfCookie: '/sanctum/csrf-cookie',
    login: 'api/auth/login',
    logout: 'api/auth/logout',
    data: 'api/auth/user'
}

const users = {
    changePassword (id: number): string {
        return `api/users/${id}/password`
    },
    ...getBaseCrudApi('users')
}

const notes = {
    set: (message: Message): string => {
        return `api/mail/messages/${message.id}/set-note`
    },
}

const tags = {
    assign: (tag: Tag): string => {
        return `api/labels/${tag.id}/assign`
    },
    unassign: (tag: Tag): string => {
        return `api/labels/${tag.id}/unassign`
    },
    ...getBaseCrudApi('labels')
}

const version = {
    get: 'api/version'
}

// region api/mail
const accounts = {
    testImap (account: Account): string {
        return `api/mail/accounts/${account.id}/check-connection/imap`
    },
    testSmtp (account: Account): string {
        return `api/mail/accounts/${account.id}/check-connection/smtp`
    },
    gmailRedirect (account: Account): string {
        return `api/services/${account.id}/gmail/oauth/redirect`
    },
    ...getBaseCrudApi('mail/accounts')
}

const messages = {
    search: 'api/mail/messages/search',
    show: (id: number | string): string => {
        return `api/mail/messages/${id}`
    },
    getBody: {
        withMedia: (message: Message): string => {
            return `api/mail/messages/${message.id}/get-body/with-media`
        },
        withoutMedia: (message: Message): string => {
            return `api/mail/messages/${message.id}/get-body/without-media`
        }
    },
    getAttachments: (message: Message): string => {
        return `api/mail/messages/${message.id}/get-attachments`
    },
    send: (account: Account): string => {
        return `api/mail/messages/${account.id}/send`
    },
    mass: {
        move: (account: Account): string => {
            return `api/mail/messages/mass/move/${account.id}`
        },
        copy: (account: Account): string => {
            return `api/mail/messages/mass/copy/${account.id}`
        },
        mark: 'api/mail/messages/mass/mark',
        delete: 'api/mail/messages/mass/delete',
    }
}

const trading = {
    assignContractor: (message: Message): string => {
        return `api/trading/contractors/${message.id}/set`
    },
    assignOrder: (message: Message): string => {
        return `api/trading/orders/${message.id}/set`
    },
    messagesWithContractor: 'api/trading/contractors/messages',
    messagesWithOrder: 'api/trading/contractors/messages',
}

const folders = {
    withStatus: (account: Account): string => {
        return (`api/mail/folders/${account.id}/with-status`)
    },
    rename: (account: Account): string => {
        return (`api/mail/folders/${account.id}/rename`)
    },
    remove: (account: Account): string => {
        return (`api/mail/folders/${account.id}`)
    },
    create: (account: Account): string => {
        return (`api/mail/folders/${account.id}`)
    }
}
// endregion

const services = {
    deepl: {
        translate: 'api/services/deepl/translate'
    },
    transkom: {
        texts: '/api/services/transkom/texts'
    }
}

const daemons = {
    runTimes: 'api/daemons/last-run-timestamps',
    run: (name: string): string => {
        return `api/daemons/${name}/run`
    },
}

const sorters = {
    run: 'api/sorters/run',
    activate: (id: number): string => `api/sorters/${id}/activate`,
    deactivate: (id: number): string => `api/sorters/${id}/deactivate`,
    ...getBaseCrudApi('sorters')
}

// region templates
const templates = {
    getParams: 'api/templates/params',
    ...getBaseCrudApi('templates')
}

const customers = {
    search: 'api/customers'
}

const orders = {
    search: 'api/orders'
}
// endregion

export {
    accounts,
    auth,
    customers,
    daemons,
    folders,
    messages,
    notes,
    orders,
    services,
    sorters,
    tags,
    templates,
    trading,
    users,
    version
}
