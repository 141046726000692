export default {
    assign: {
        'name': 'Przypisz',
        'order-only': 'Tylko zamówienie',
        'contractor-only': 'Tylko kontrahenta',
        'both-order-contractor': 'Zamówienie i kontrahenta',
    },
    assignment: 'Dopisanie',
    bestellnummer: 'Bestellnummer',
    bkod: 'BKOD',
    cancellation: 'Anulowanie',
    case: 'Sprawa',
    comment: 'Komentarz',
    inquiry: 'Zapytanie',
    is_downloaded: 'Pobranie',
    is_paid: 'Opłacenie',
    is_sent: 'Wysłanie',
    item_quantity: 'Ilość produktu',
    item_sku: 'SKU produktu',
    item_title: 'Tytuł produktu',
    listing_id: 'Nr aukcji',
    login: 'Login',
    ordered_at: 'Data zamówienia',
    paid_at: 'Data płatności',
    payment_method: 'Rodzaj zapłaty',
    return: 'Zwrot',
    shipment_method: 'Rodzaj transportu',
    shipped_at: 'data wysyłki',
    surname: 'Nazwisko',
    tracking_number: 'Numer paczki',
    transaction_id: 'ID transakcji',
    product_title: 'Tytuł produktu [{item_title}]',
    explore_bos: 'Wyszukaj w BOS',
    protocol_number: 'Numer protokołu',
    order_number: 'Numer zamówienia',
    max_shipment_date: 'Termin graniczny wysyłki',
    variations: {
        parameter_1: 'Parametr 1.',
        parameter_2: 'Parametr 2.',
        parameter_3: 'Parametr 3.'
    },
    quantity: 'Ilość',
    price_currency: 'Cena + waluta',
    anzeigen: 'Anzeigen',
    net_pln: 'Netto PLN',
    thumbnail: 'Miniaturka',
    product_sku: 'SKU produktu',
    product_bkod: 'BKOD produktu'
}
