import localforage from 'localforage'

import { PartialStoreState, StoreState } from '@/store/state'
import { VuexPersist } from '@/store/plugins/vuex-persist'

const persistMutations = [
    'auth/setAuthenticatedUser', 'auth/unsetAuthenticatedUser',
    'preferences/setRightSideMenu'
]

const persist = new VuexPersist<StoreState, PartialStoreState>({
    asyncStorage: true,
    key: 'vuex',
    storage: localforage,
    strictMode: true,
    reducer: (state: StoreState): PartialStoreState => ({
        auth: {
            authenticatedUser: state.auth.authenticatedUser
        }
    }),
    filter: mutation => persistMutations.includes(mutation.type)
})

export default persist.plugin
