import Store from '@/store/store'
import Vue from 'vue'
import VueRouter, { NavigationGuard } from 'vue-router'
import routes from '@/router/routes'
import { IsGuest, IsLogged, RaceConditionKiller } from '@/router/guards'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    routes
})

const guards: Array<NavigationGuard> = [
    RaceConditionKiller(Store),
    IsGuest(Store),
    IsLogged(Store)
]

guards.forEach(guard => router.beforeEach(guard))

export default router
