import { merge } from 'lodash'
import { MutationPayload, Plugin, Store } from 'vuex'

import { AsyncStorage, PersistedStore } from '@/store/plugins/vuex-persist/types'
import { VuexPersistDelegate } from '@/store/plugins/vuex-persist/delegates/delegate'

import { Options } from '@/store/plugins/vuex-persist/options'

class AsyncStorageDelegate<S extends Record<string, unknown>, T = Partial<S>> implements VuexPersistDelegate<S, T> {
    private options: Options<S, T>

    public constructor (options: Options<S, T>) {
        this.options = options
    }

    public restoreState (key: string): Promise<S> {
        return (this.options.storage as AsyncStorage).getItem(key).then(value => {
            if (value === undefined) {
                return {}
            }

            if (typeof value === 'string') {
                return JSON.parse(value)
            }

            return value
        })
    }

    public saveState (key: string, state: S | T): Promise<S | T> {
        return (this.options.storage as AsyncStorage).setItem(key, merge({}, state))
    }

    get plugin (): Plugin<S> {
        return (store: Store<S>) => {
            const persistedStore = store as PersistedStore<S>

            persistedStore.restored = (this.restoreState(this.options.key) as Promise<S>)
                .then(restoredState => {
                    if (this.options.strictMode) {
                        store.commit('RESTORE_MUTATION', restoredState)
                    } else {
                        store.replaceState(merge({}, store.state, restoredState))
                    }

                    store.subscribe(async (mutation: MutationPayload, state: S) => {
                        if (this.options.filter(mutation)) {
                            await this.saveState(this.options.key, this.options.reducer(state))
                        }
                    })
                }) as Promise<S>
        }
    }
}

export {
    AsyncStorageDelegate
}
