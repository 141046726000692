/* eslint-disable @typescript-eslint/no-explicit-any */

const validate = (files: File | Array<File> | undefined | null): boolean => {
    const regex = /\.(jpg|svg|jpeg|png|bmp|gif)$/i

    if (Array.isArray(files)) {
        return files.every(file => regex.test(file.name))
    }

    if (files !== undefined && files !== null) {
        return regex.test(files.name)
    }

    return false
}

export default {
    validate
}
