import { IIdentifiable } from '@/models/models'

interface ICustomer extends IIdentifiable {
    nickname: string
    name: string
}

class Customer implements ICustomer {
    id = 0
    nickname = ''
    name = ''

    constructor (data?: ICustomer) {
        if (data !== undefined) {
            this.id = data.id
            this.nickname = data.nickname
            this.name = data.name
        }
    }
}

export { Customer }
export type { ICustomer }
