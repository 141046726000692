
    import Vue from 'vue'
    import { Component } from 'vue-property-decorator'
    import TransferMode from '@/store/TransferMode'
    import { Tag } from '@/models/data/tag'
    import { Message } from '@/models/data/message'
    import { MessageFlagName } from '@/api/enums'
    import { getModule } from 'vuex-module-decorators'
    import Messages from '@/store/modules/messages'
    import Notifications from '@/lib/notifications'
    import ComponentTransferMessages from '@/components/messages/ComponentTransferMessages.vue'
    import Tags from '@/store/modules/tags'

    @Component({
        components: { ComponentTransferMessages }
    })
    export default class ComponentMessagesMassOperations extends Vue {
        messagesModule = getModule(Messages, this.$store)
        tagsModule = getModule(Tags, this.$store)

        loading = false
        dialog = {
            transferMessage: {
                enabled: false,
                mode: TransferMode.MOVE
            }
        }

        saving = {
            assignTags: false,
            deleteSelected: false,
            markAsRead: false,
            markAsUnread: false,
            note: false,
            unassignTags: false
        }

        tagsToAssign: Array<Tag> = []
        tagsToUnassign: Array<Tag> = []

        get selectedMessages (): Array<Message> {
            return this.messagesModule.selectedMessages
        }

        set selectedMessages (value: Array<Message>) {
            this.messagesModule.setSelectedMessages(value)
        }

        get tags (): Array<Tag> {
            return this.tagsModule.entries
        }

        showMovingDialog (): void {
            this.dialog.transferMessage.enabled = true
            this.dialog.transferMessage.mode = TransferMode.MOVE
        }

        showCopyingDialog (): void {
            this.dialog.transferMessage.enabled = true
            this.dialog.transferMessage.mode = TransferMode.COPY
        }

        async enableDeepSearch (): Promise<void> {
            this.messagesModule.setDeepSearch(true)
            this.messagesModule.setUsedDeepSearch(true)
            await this.search()
            this.messagesModule.setDeepSearch(false)
        }

        async assignTags (): Promise<void> {
            this.saving.assignTags = true
            await this.messagesModule.assignTags(this.tagsToAssign)
            this.saving.assignTags = false
        }

        async unassignTags (): Promise<void> {
            this.saving.unassignTags = true
            await this.messagesModule.unassignTags(this.tagsToUnassign)
            this.saving.unassignTags = false
        }

        async markSelectedAsRead (): Promise<void> {
            this.saving.markAsRead = true
            this.messagesModule.selectedMessages.forEach((message: Message) => this.messagesModule.startMessageLoading(message))
            try {
                await this.messagesModule.massMark({ name: MessageFlagName.SEEN, value: true })
            } finally {
                this.messagesModule.selectedMessages.forEach((message: Message) => this.messagesModule.stopMessageLoading(message))
                this.saving.markAsRead = false
            }
        }

        async markSelectedAsUnread (): Promise<void> {
            this.saving.markAsUnread = true
            this.messagesModule.selectedMessages.forEach((message: Message) => this.messagesModule.startMessageLoading(message))
            try {
                await this.messagesModule.massMark({ name: MessageFlagName.SEEN, value: false })
            } finally {
                this.messagesModule.selectedMessages.forEach((message: Message) => this.messagesModule.stopMessageLoading(message))
                this.saving.markAsUnread = false
            }
        }

        async deleteSelected (): Promise<void> {
            this.saving.deleteSelected = true
            await this.messagesModule.massDelete()
            this.saving.deleteSelected = false
        }

        async search (): Promise<void> {
            this.loading = true
            try {
                await this.messagesModule.search()
            } catch (e) {
                Notifications.error(this.$t('messages.error.search') as string)
            } finally {
                this.loading = false
            }
        }
    }
