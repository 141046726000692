
    import { TranslateResult } from 'vue-i18n/types'
    import { getModule } from 'vuex-module-decorators'
    import Vue from 'vue'
    import { Component } from 'vue-property-decorator'
    import Auth from '@/store/modules/auth'
    import Interface from '@/store/modules/interface'
    import ComponentSearch from '@/components/layout/ComponentSearch.vue'
    import ComponentMessagesMassOperations from '@/components/messages/ComponentMessagesMassOperations.vue'

    @Component({
        components: { ComponentSearch, ComponentMessagesMassOperations }
    })
    export default class ComponentNavigation extends Vue {
        entries: Array<{
            icon: string
            text: TranslateResult
            path: string | null
        }> = [
            {
                icon: 'mdi-email-outline',
                text: this.$t('navigation.messages'),
                path: '/messages',
            },
            {
                icon: 'mdi-sort-clock-descending-outline',
                text: this.$t('navigation.sorter'),
                path: '/sorters',
            },
            {
                icon: 'mdi-application-edit-outline',
                text: this.$t('navigation.templates'),
                path: '/templates',
            },
            {
                icon: 'mdi-cog-outline',
                text: this.$t('navigation.settings'),
                path: '/settings',
            }
        ]

        get isMessagesList (): boolean {
            return this.$route.name === 'messages.list' || this.$route.name === 'home'
        }

        async logout (): Promise<void> {
            await getModule(Auth, this.$store).logout()
            this.$router.push({ name: 'auth.login' })
        }

        toggleSidebarVisibility (): void {
            getModule(Interface, this.$store).toggleSidebarVisibility()
        }
    }
