import { merge } from 'lodash'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
function restoreMutation<S extends Record<string, unknown>> (this: any, state: S, savedState?: S): void {
    const mergedState = savedState !== undefined
        ? merge({}, state, savedState)
        : state

    for (const prop of Object.keys(mergedState)) {
        this._vm.$set(state, prop, mergedState[prop])
    }
}

export {
    restoreMutation as RESTORE_MUTATION
}
