import { Action, Module, Mutation } from 'vuex-module-decorators'
import BaseModule from '@/store/modules/base-module'
import { AccountModuleState } from '@/store/state'
import { PaginatedResponse } from '@/models/data/pagination/paginated-response'
import AccountRepository from '@/repositories/account-repository'
import NotFoundError from '@/errors/not-found-error'
import { Account } from '@/models/data/account'
import FolderRepository from '@/repositories/folder-repository'
import { Folder, Status } from '@/models/data/folder'
import { clone } from 'lodash'
import Mode from '@/store/mode'

interface ISetAccountFoldersPayload {
    account: Account
    folders: Folder[]
}

@Module({ name: 'accounts', namespaced: true })
export default class Accounts extends BaseModule implements AccountModuleState {
    private static errorTag = 'AccountsStoreModule'

    entries = new PaginatedResponse<Account>()
    loading = false
    newFolderEntry: Folder = new Folder()
    selectedFolderEntry: Folder = new Folder()
    mode: Mode = Mode.EDIT

    @Mutation
    setEntries (payload: PaginatedResponse<Account>): void {
        this.entries = payload
    }

    @Mutation
    setNewFolderEntry (payload: { account: Account, folder: Folder }): void {
        this.newFolderEntry = new Folder({ account: payload.account, path: payload.folder.path, name: '', folders: [], status: new Status() })
    }

    @Mutation
    setAccountFolders (payload: ISetAccountFoldersPayload): void {
        payload.account.folders = payload.folders
    }

    @Mutation
    setSelectedFolderEntry (folder: Folder): void {
        this.selectedFolderEntry = clone(folder)
    }

    @Mutation
    setMode (mode: Mode): void {
        this.mode = mode
    }

    @Mutation
    setFolderProperty<V extends keyof Folder> (payload: { key: V, value: Folder[V] }): void {
        if (this.mode === Mode.CREATE) {
            this.newFolderEntry[payload.key] = payload.value
        } else {
            this.selectedFolderEntry[payload.key] = payload.value
        }
    }

    @Mutation
    setImapTestResult (payload: { account: Account, isPassed: boolean }): void {
        payload.account.test_results.imap = payload.isPassed
    }

    @Mutation
    setSmtpTestResult (payload: { account: Account, isPassed: boolean }): void {
        payload.account.test_results.smtp = payload.isPassed
    }

    @Mutation
    setLoadingTestImap (payload: { account: Account, isLoading: boolean }): void {
        payload.account.loading.test.imap = payload.isLoading
    }

    @Mutation
    setLoadingTestSmtp (payload: { account: Account, isLoading: boolean }): void {
        payload.account.loading.test.smtp = payload.isLoading
    }

    @Action
    async loadPage (): Promise<void> {
        const data = await AccountRepository.index()

        if (data.meta.current_page > data.meta.last_page) {
            throw new NotFoundError(Accounts.errorTag)
        }

        this.setEntries(data)
    }

    @Action
    async loadFolders (): Promise<void> {
        for (const account of this.entries.data) {
            const folders = await FolderRepository.getWithStatusForAccount(account)
            this.setAccountFolders({ account: account, folders: folders })
        }
    }

    @Action
    async renameFolder (account: Account): Promise<void> {
        const folder = this.selectedFolderEntry
        await FolderRepository.update(account, folder)
        await this.loadFolders()
    }

    @Action
    async removeFolder (payload: { account: Account, folder: Folder }): Promise<void> {
        await FolderRepository.remove(payload.account, payload.folder)
        await this.loadFolders()
    }

    @Action
    async createFolder (account: Account): Promise<void> {
        await FolderRepository.create(account, this.newFolderEntry)
        await this.loadFolders()
    }

    @Action
    async testImap (account: Account): Promise<void> {
        this.setLoadingTestImap({ account, isLoading: true })
        const isPassed = await AccountRepository.testImap(account)
        this.setImapTestResult({ account, isPassed })
        this.setLoadingTestImap({ account, isLoading: false })
    }

    @Action
    async testSmtp (account: Account): Promise<void> {
        this.setLoadingTestSmtp({ account, isLoading: true })
        const isPassed = await AccountRepository.testSmtp(account)
        this.setSmtpTestResult({ account, isPassed })
        this.setLoadingTestSmtp({ account, isLoading: false })
    }

    @Action
    async update (account: Account): Promise<void> {
        await AccountRepository.update(account)
        await this.loadPage()
    }
}
