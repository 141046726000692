import { CrudApi, PaginatedCrudApi } from '@/api/types'

const getBaseCrudApi = (url: string): CrudApi => {
    return {
        index: `api/${url}`,
        store: `api/${url}`,
        update (id: number): string {
            return `api/${url}/${id}`
        },
        remove (id: number): string {
            return `api/${url}/${id}`
        }
    }
}

const getPaginatedCrudApi = (url: string): PaginatedCrudApi => {
    return {
        index (page: number): string {
            return `api/${url}?page=${page}`
        },
        store: `api/${url}`,
        update (id: number): string {
            return `api/${url}/${id}`
        },
        remove (id: number): string {
            return `api/${url}/${id}`
        }
    }
}

export { getBaseCrudApi, getPaginatedCrudApi }
