export default {
    add: 'Dodaj',
    color: 'Kolor',
    create: 'Utwórz',
    created_at: 'Utworzona',
    delete: 'Usuń',
    dismiss: 'Anuluj',
    edit: 'Edytuj',
    error: {
        create: 'Nie udało się utworzyć.',
        delete: 'Nie udało się usunąć.',
        update: 'Nie udało się zaktualizować.'
    },
    name: 'Nazwa',
    save: 'Zapisz',
    success: {
        created: 'Utworzono',
        deleted: 'Usunięto',
        updated: 'Zaktualizowano'
    },
    update: 'Aktualizuj',
    updated_at: 'Zaktualizowana'
}
